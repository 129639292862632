import { useRouter } from 'next/router';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useRef, useState } from 'react';

import { useUser } from '@/services/Hooks/useUser';

interface IProps {
	hideChat: boolean;
}

const LiveChat: FC<IProps> = ({ hideChat }) => {
	const chatWindowRef = useRef<HTMLElement | null>(null);
	const { t } = useTranslation('common');
	const router = useRouter();
	const { userDetails } = useUser();
	const pocId = userDetails?.username ? t('poc.pocIdLogin') : t('poc.pocId');
	const [isChatScriptReady, setIsChatScriptReady] = useState<boolean>(false);

	const removeChat = () => {
		chatWindowRef.current = document.querySelector('.ie-div-position-customer-chat');
		if (window.isIcPatronChatInit && chatWindowRef.current) {
			chatWindowRef.current.remove();
			window.isIcPatronChatInit = false;
		}
	};

	const renderLiveChat = (): boolean => {
		chatWindowRef.current = document.querySelector('.ie-div-position-customer-chat');
		if (window.icPatronChat && pocId) {
			// If a chat window has already been initialized, remove it before creating a new one
			if (window.isIcPatronChatInit && chatWindowRef.current) {
				chatWindowRef.current.remove();
				window.isIcPatronChatInit = false;
			}

			if (!window.isIcPatronChatInit) {
				window.icPatronChat.init({
					bus_no: 4599361,
					params: [userDetails?.username || '', '', userDetails?.email || '', ''],
					poc: pocId,
					serverHost: 'https://home-e31.niceincontact.com',
				});
				window.isIcPatronChatInit = true;
			}
		}
		return true;
	};

	const onLiveChatScriptLoad = () => {
		setIsChatScriptReady(true);
	};

	useEffect(() => {
		if (hideChat) {
			removeChat();
		} else {
			renderLiveChat();
		}
	}, [userDetails, router.locale, isChatScriptReady, hideChat]);

	return (
		<Script
			src="https://home-e31.niceincontact.com/inContact/ChatClient/js/embed.min.js"
			strategy="lazyOnload"
			onLoad={onLiveChatScriptLoad}
		/>
	);
};

export default LiveChat;

import { ICustomerStore } from '@/redux/StateSlices/Customer/index.d';

export const CustomerInitialState: ICustomerStore.IState = {
	addresses: [
		{
			buildingname: '',
			buildingnumber: '',
			country: '',
			countryname: '',
			county: '',
			postcode: '',
			street: '',
			subbuildingname: '',
			town: '',
		},
	],
	amount: '',
	apitoken: '',
	application_type: null,
	category: '',
	creditconsent: false,
	dealership: null,
	dob: '',
	email: '',
	forename: '',
	integration: false,
	marketing: false,
	marketing_options: {
		mail: false,
		post: false,
		sms: false,
	},
	mobile: '',
	national_id_number: '',
	preapproved: false,
	registration: '',
	repaymentdate: '',
	salink: false,
	supplier: null,
	surname: '',
	verificationemail: '',
};

import Link from 'next/link';
import { FC } from 'react';

import { ILink, OpenWith, Target, Variant } from '@/components/Link/index.d';

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'base':
			return 'text-secondary-content';
		case 'primary':
			return 'text-primary-content';
		default:
			return 'text-secondary-accent';
	}
};

const linkType = (openWith: OpenWith) => {
	switch (openWith) {
		case 'email':
			return 'mailto:';
		case 'tel':
			return 'tel:';
		default:
			return '';
	}
};

const getTarget = (target: Target) => {
	switch (target) {
		case 'self':
			return 'self';
		case 'parent':
			return 'parent';
		case 'top':
			return 'top';
		default:
			return 'blank';
	}
};

export const LinkClickOnly: FC<ILink.IClickOnlyProps> = (props) => {
	const { onClick, variant = 'accent', children, className, underline } = props;
	return (
		<span
			className={`hover:underline ${underline ? 'border-b' : ''} ${getColor(variant)} ${className}`}
		>
			<button type="button" onClick={onClick}>
				{children}
			</button>
		</span>
	);
};

export const LinkInternal: FC<ILink.IProps> = (props) => {
	const { href, variant = 'accent', children, className, as, passHref = false } = props;
	return (
		<span className={`hover:underline ${getColor(variant)} ${className}`}>
			<Link passHref={passHref} href={href} as={as}>
				{children}
			</Link>
		</span>
	);
};

export const LinkExternal: FC<ILink.IProps> = (props) => {
	const {
		href,
		variant = 'accent',
		children,
		openWith = 'site',
		target = 'blank',
		className,
	} = props;

	return (
		<span className={`hover:underline ${getColor(variant)} ${className}`}>
			<a href={`${linkType(openWith)}${href}`} target={`_${getTarget(target)}`} rel="noreferrer">
				{children}
			</a>
		</span>
	);
};

export default {
	LinkClickOnly,
	LinkExternal,
	LinkInternal,
};

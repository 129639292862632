import { GET_API_LAST_LOGIN, USER_DETAILS } from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';

import { IUserAPI } from './index.d';

export const UserApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		getApiLastLogin: builder.query<IUserAPI.IGetApiLastLoginResponse, void>({
			query: () => ({
				method: 'POST',
				url: GET_API_LAST_LOGIN,
			}),
		}),
		getUser: builder.query<IUserAPI.IGetUserResponse, void>({
			query: () => ({
				method: 'GET',
				url: USER_DETAILS,
			}),
		}),
		updateUser: builder.query<IUserAPI.IUpdateUserResponse, IUserAPI.IUpdateUserRequest>({
			query: (body) => ({
				data: body,
				method: 'POST',
				url: USER_DETAILS,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetUserQuery,
	useLazyGetUserQuery,
	useGetApiLastLoginQuery,
	useLazyGetApiLastLoginQuery,
	useLazyUpdateUserQuery,
} = UserApi;

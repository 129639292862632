import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FunctionComponent, useEffect, useState } from 'react';

import Icon from '@/components/Icon';
import Image from '@/components/Image';
import Select from '@/components/Select/index';
import { Caption } from '@/components/Text';
import { COUNTRY_OPTION_LIST } from '@/constants/locales';
import IFooterTypes from '@/modules/Footer/index.d';
import { useUser } from '@/services/Hooks/useUser';
import getRedirectPath from '@/services/Utils/Routing/getRedirectPath';

const FooterContact: FunctionComponent = () => {
	const router = useRouter();
	const { t } = useTranslation('footer');
	const { isSessionExist, isVisitor } = useUser();
	const [selectedCountry, setSelectedCountry] = useState<any>();
	const initialCountryOptions: IFooterTypes.ICountryOptionsType[] = COUNTRY_OPTION_LIST.map(
		(co: IFooterTypes.ICountryOptionsType) => ({
			...co,
			name: t(`countryNames.${co.countryCode}`),
		})
	);
	const [countryOptions, setCountryOptions] =
		useState<IFooterTypes.ICountryOptionsType[]>(initialCountryOptions);

	const changeLocale = (e: any) => {
		const newPath = getRedirectPath({
			currentLocale: router.locale,
			path: router.asPath,
			redirectLocale: e?.countryCode,
		});

		router.push(newPath, newPath, { locale: e?.countryCode });
	};

	useEffect(() => {
		const opts = [...countryOptions];
		const findedCountry = opts.find((i) => i.countryCode === router?.locale);
		if (findedCountry) {
			const filterRestOfCountries = opts.filter((o) => o.countryCode !== findedCountry.countryCode);
			setSelectedCountry(findedCountry);
			setCountryOptions([findedCountry, ...filterRestOfCountries]);
		}
	}, [router]);

	return (
		<div className="block md:flex justify-between xl:items-center mt-2">
			<div className="xl:flex md:mr-4 xl:ml-0 flex-1">
				{!isSessionExist || isVisitor ? (
					<div className="flex items-center">
						<Icon iconName={faMapMarkerAlt} variant="primary" className="mr-0.5" />
						<div className="w-full sm:w-72 hover:cursor-pointer">
							<Select
								options={countryOptions}
								onChange={(e: any) => {
									changeLocale(e);
								}}
								isSearchable={false}
								value={selectedCountry}
								onBlur={() => {}}
								variant="primary"
								controlClassName="bg-transparent flex items-center"
							/>
						</div>
					</div>
				) : null}
				<div
					className={`flex lg:flex-1 xl:justify-center items-center ${
						(!isSessionExist || isVisitor) && 'xl:ml-4'
					} mt-1 xl:mt-0`}
				>
					<div className="mr-1">
						<Image src="images/logos/ico.png" width="46" height="28" />
					</div>
					<div>
						<Caption className="font-bold" weight="bold">
							{t('safeAndSecure')}
						</Caption>
						<Caption>
							{t('protectionNumber')} {t('common:dataProductionNumber')}
						</Caption>
					</div>
				</div>
			</div>
			<div className="mt-1 md:mt-0">
				<Caption className="capitalize text-left">
					{t('common:copyright', { currentYear: new Date().getFullYear() })}
				</Caption>
				<Caption>{t('address')}</Caption>
			</div>
		</div>
	);
};

export default FooterContact;

// @ts-ignore
import findLast from 'array.prototype.findlast';
import { AppLayoutProps } from 'next/app';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import { ComponentType, useEffect } from 'react';

import { useGoogleTagManager } from '@/services/Hooks/useGoogleTagManager';
import { useUser } from '@/services/Hooks/useUser';

function withMarketingLayerProvider<T extends AppLayoutProps>(
	WrappedComponent: ComponentType<T>
): AppLayoutProps['Component'] {
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const Component = (props: T) => {
		// TODO: Write a TSDoc
		const { locale, events, query } = useRouter();
		const { pageProps } = props;
		const shouldSendPageViewEvent = Boolean(pageProps?.shouldSendPageView ?? true);
		const sendEvent = useGoogleTagManager();
		const { isStaff, userDetails } = useUser();

		const sendGooglePageChangeEvent = () => {
			if (!userDetails?.user_id && isStaff) {
				return;
			}

			let newURL = window.location.pathname;
			const urlArr = window.location.pathname.split('/');

			if (urlArr[1] === locale) {
				newURL = `/${urlArr.slice(2).join('/')}`;
			}

			const payload = {
				page: {
					country: locale,
					environment: process.env.NEXT_PUBLIC_ENV,
					language: locale,
					title: newURL,
				},
				user: {
					dealershipId: userDetails?.dealership_id || '',
					id: userDetails?.user_id || '',
					logInType: userDetails ? 'service advisor' : '',
					loggedIn: Boolean(userDetails),
				},
			};

			// This needs to stay like this as we need named func for "this" keyword to work
			// eslint-disable-next-line func-names
			const comparer = function (event: any) {
				return event.event === 'page view';
			};

			const dataLayer = window?.dataLayer || [];
			const lastPageView = findLast(dataLayer, comparer);

			if (shouldSendPageViewEvent) {
				if (!lastPageView || (lastPageView && lastPageView?.page?.title !== newURL)) {
					sendEvent('page view', payload);
				}
			}
		};

		useEffect(() => {
			setTimeout(() => {
				sendGooglePageChangeEvent();
			}, 1000);
			events.on('routeChangeComplete', sendGooglePageChangeEvent);

			return () => {
				events.off('routeChangeComplete', sendGooglePageChangeEvent);
			};
		}, [userDetails?.user_id]);

		useEffect(() => {
			// Set RC Token from Grace & Courage
			if (query?.rc) {
				setCookie(null, 'rc_token', query.rc as string, {
					maxAge: 60 * 60 * 24,
					path: '/',
					sameSite: 'none',
					secure: true,
				});
			}
		}, []);

		return <WrappedComponent {...props} />;
	};

	Component.displayName = `withMarketingLayerProvider(${displayName})`;

	return Component;
}

export default withMarketingLayerProvider;

import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { LinkExternal } from '@/components/Link';
import { Body } from '@/components/Text';
import IFooterTypes from '@/modules/Footer/index.d';
import getRedirectPath from '@/services/Utils/Routing/getRedirectPath';
import { capitalizeFirstLetter } from '@/services/Utils/String/capitalizeFirstLetter';

const FooterColumn: FC<IFooterTypes.IFooterColumn> = ({ menuItems, title }) => {
	const { locale } = useRouter();

	return (
		<div className="col-span-2 lg:col-span-1 mt-1.5 md:mt-0 mb-1">
			<Body className="text-xl mb-1 font-bold" weight="bold">
				{' '}
				{title ? capitalizeFirstLetter(title) : ''}
			</Body>
			<ul>
				{menuItems?.map((menuItem: { name: string; path: string; external?: boolean }) => {
					const capitalizedText = capitalizeFirstLetter(menuItem.name);
					return (
						<li className="cursor-pointer underline mb-0.5" key={menuItem.name}>
							{menuItem?.external ? (
								<LinkExternal variant="primary" href={menuItem.path}>
									{menuItem.name}
								</LinkExternal>
							) : (
								<Link
									href={getRedirectPath({
										currentLocale: locale,
										path: menuItem.path,
										redirectLocale: menuItem.path,
									})}
								>
									{capitalizedText}
								</Link>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default FooterColumn;

import { useEffect, useState } from 'react';

const useBrowserMounted = () => {
	const [mounted, setMounted] = useState<boolean>(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	return mounted;
};

export default useBrowserMounted;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMarketplaceApplicationStore } from '@/redux/StateSlices/MarketplaceApplication/index.d';

import { MarketplaceApplicationInitialState as initialState } from './initial';

const MarketplaceApplication = createSlice({
	initialState,
	name: 'marketplaceApplication',
	reducers: {
		resetState() {
			return {
				...initialState,
			};
		},
		setRequest: (
			state,
			action: PayloadAction<IMarketplaceApplicationStore.IApplicationRequest, string>
		) => {
			state.request = action.payload;
		},
		setResult: (
			state,
			action: PayloadAction<IMarketplaceApplicationStore.IApplicationResult, string>
		) => {
			state.result = action.payload;
		},
	},
});
export const marketplaceApplicationReducerName = MarketplaceApplication.name;
export const marketplaceApplicationReducer = MarketplaceApplication.reducer;
export const { resetState, setRequest, setResult } = MarketplaceApplication.actions;

export default MarketplaceApplication;

import { IApplicationStore } from './index.d';

export const ApplicationInitialState: IApplicationStore.IState = {
	address: '',
	amount: '',
	application_id: '',
	application_wired: true,
	car_logo: '',
	card_ending: null,
	complete_now_not_available: false,
	completed_wired: false,
	country_code: 'GB',
	customer_address: '',

	dealership_address: '',

	// Frontend setters
	dealership_id: 0,

	dealership_name: '',

	dealership_tel: '',

	dealership_web: '',
	deposit: false,
	deposit_amount: '',
	dob: '',
	flagdate: true,
	forename: '',
	formAmount: '',
	full_name: 'Accepttest Accepttest',
	initial_amount: '0',
	initialized: false,
	instalments: [],
	is_save_for_later_used: false,
	mandateapproved: false,
	max: 0,
	min: 0,
	national_id_number: '',
	postcode: '',
	preapprovalcode: null,
	preapproved_wired: false,
	product_id: 0,
	repaymentdate: '2022-01-28',
	retry: true,
	selectedCustomerType: undefined,
	selectedPaymentType: undefined,
	signed: false,
	solaid: '',
	surname: '',
	vehicle_reg: '',
};

import * as Sentry from '@sentry/nextjs';
import { SeverityLevel } from '@sentry/nextjs';
import { AxiosError } from 'axios';

class SentryError extends Error {
	constructor(name: string, message: string) {
		super(name);
		this.name = name;
		this.message = message;
	}
}

export enum Severity {
	Fatal = 'fatal',
	Error = 'error',
	Warning = 'warning',
	Log = 'log',
	Info = 'info',
	Debug = 'debug',
}

export type ErrorCategory =
	| 'general'
	| 'Core API'
	| 'payment'
	| 'application'
	| 'auth'
	| 'Generic API';
export type SeverityType = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

const getSeverityEnum = (severity: SeverityType): SeverityLevel => {
	switch (severity) {
		case 'fatal':
			return Severity.Fatal;
		case 'debug':
			return Severity.Debug;
		case 'error':
			return Severity.Error;
		case 'info':
			return Severity.Info;
		case 'log':
			return Severity.Log;
		case 'warning':
			return Severity.Warning;
		default:
			return Severity.Error;
	}
};

const isSentryActive =
	process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'demo';
const isSentryDebug =
	process.env.NEXT_PUBLIC_ENV === 'local' ||
	process.env.NEXT_PUBLIC_ENV === 'win' ||
	process.env.NEXT_PUBLIC_ENV === 'big';

const captureExceptionAndLogToSentry = (
	name: string,
	message: string,
	detail: string,
	errorObj: Record<string, any>,
	category: ErrorCategory,
	severity: SeverityType
) => {
	Sentry.captureException(new SentryError(name, message), (scope) => {
		scope.clear();
		scope.setTag('category', category);
		scope.setLevel(getSeverityEnum(severity));
		scope.setExtra('err', errorObj);
		scope.setTransactionName(detail);
		return scope;
	});
};

const apiUrlWhiteList = [
	{
		statuses: [400, 406],
		url: 'core/vehicle/info/',
	},
	{
		statuses: [400, 404],
		url: 'core/retention/dealership',
	},
	{
		statuses: [406, 403],
		url: 'core/user/reward',
	},
	{
		statuses: [400, 401],
		url: 'core/dealership/dealership-stats',
	},
	{
		statuses: [400, 401],
		url: 'core/dealership/dealership-details',
	},
	{
		statuses: [400, 403],
		url: 'core/user/dashboard',
	},
	{
		statuses: [403],
		url: 'core/user/api-last-login',
	},
	{
		statuses: [406, 400],
		url: 'core/user/resetpassword',
	},
	{
		statuses: [400],
		url: 'core/user/advisor-accountrecovery',
	},
	{
		statuses: [400, 403],
		url: 'core/user/userdetail',
	},
	{
		statuses: [404],
		url: 'core/customer/api-token-obtain',
	},
	{
		statuses: [400, 404],
		url: 'core/customer/retention/dealership',
	},
	{
		statuses: [400, 401],
		url: 'core/customer/get-my-country',
	},
	{
		statuses: [401, 403],
		url: 'core/notification/notification',
	},
	{
		statuses: [400],
		url: 'core/application/cancel',
	},
	{
		statuses: [400, 401, 403, 404],
		url: 'core/application/applications',
	},
	{
		statuses: [400],
		url: 'core/application/idconsumer',
	},
	{
		statuses: [400, 403],
		url: 'core/application/onlinerepayment',
	},
	{
		statuses: [400, 406],
		url: 'core/application/selectdealership',
	},
	{
		statuses: [400],
		url: 'core/dealership/available/days',
	},
	{
		statuses: [401],
		url: 'core/dealership/brands',
	},
	{
		statuses: [400],
		url: 'core/dealership/group/dealerships',
	},
	{
		statuses: [400, 401],
		url: 'core/dealership/postcodenearby',
	},
	{
		statuses: [400],
		url: 'core/booking/create/appointment',
	},
	{
		statuses: [400],
		url: 'core/idv/create/applicant',
	},
];

const isApiURLErrorInWhiteList = (url: string, status?: number) => {
	const selectedUrl = apiUrlWhiteList.filter((item) => item.url.includes(url))[0];
	let isInList = false;
	if (selectedUrl && status) {
		isInList = Boolean(selectedUrl.statuses.filter((item) => item === status)[0]);
	}

	return isInList;
};

const captureApiException = (error: AxiosError, type: 'Bumper' | 'Generic') => {
	try {
		const method = error?.config?.method ? error?.config?.method?.toUpperCase() : null;
		const url = error?.config?.url ? error?.config?.url : null;
		const status = error?.response?.status;
		const ignoreStatus = Boolean(error?.config?.data?.ignoreSentry);

		const errorPayload = {
			request: error?.config?.data ? JSON.parse(error?.config?.data) : {},
			response: error?.response?.data,
		};

		const name = type === 'Bumper' ? `Bumper Api Error on ${url}` : `Generic Api Error on ${url}`;
		const category = type === 'Bumper' ? 'Core API' : 'Generic API';

		const isNotWhiteListed = !isApiURLErrorInWhiteList(url || '', status);
		const shouldCaptureException = isNotWhiteListed && method && url && status && !ignoreStatus;

		if (shouldCaptureException) {
			captureExceptionAndLogToSentry(
				name,
				`Error response received with status code: ${status}`,
				`${method} ${url}`,
				errorPayload,
				category,
				'error'
			);
		}
	} catch (e) {
		return e;
	}
	return true;
};

export { captureApiException, captureExceptionAndLogToSentry, isSentryActive, isSentryDebug };

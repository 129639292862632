// eslint-disable-next-line check-file/folder-naming-convention
import { faEnvelope, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { faSmile } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useRef, useState } from 'react';

import Icon from '@/components/Icon';
import NotificationItem from '@/components/Navbar/NotificationItem';
import { Body } from '@/components/Text';
import { notificationSelector, setIsNotificationsRead } from '@/redux/StateSlices/User';
import { useAppDispatch, useTypedSelector } from '@/redux/store';
import { useUser } from '@/services/Hooks/useUser';

const NotificationButton: FC<{}> = () => {
	const { t } = useTranslation('navbar');
	const wrapperRef = useRef<HTMLDivElement>(null);
	const isNotificationsRead = useTypedSelector(notificationSelector);
	const { notifications } = useUser();
	const dispatch = useAppDispatch();
	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (isActive && wrapperRef?.current && !wrapperRef.current?.contains(e.target)) {
				setIsActive(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [isActive]);

	const handleNotificationClick = () => {
		setIsActive((dState) => !dState);
		if (!isActive) {
			dispatch(setIsNotificationsRead(true));
		}
	};

	return (
		<div className="relative" ref={wrapperRef}>
			<div
				role="button"
				onKeyDown={handleNotificationClick}
				tabIndex={-1}
				onClick={handleNotificationClick}
				className="border bg-transparent hover:bg-tertiary-contrast text-base px-0.75 py-0.25 rounded-full flex flex-row items-center space-x-0.5 cursor-pointer"
			>
				<Body>{String(isNotificationsRead ? 0 : notifications?.count || 0)}</Body>
				<Icon iconName={faEnvelope} />
			</div>
			{isActive && (notifications?.pending?.name || notifications?.preapproval?.name) ? (
				<div className="w-80 absolute flex flex-col space-y-0.5 py-0.5 top-9 right-0 border block bg-tertiary text-tertiary-content rounded-xl pl-1 pr-0.25 z-50">
					{notifications?.pending?.name ? (
						<NotificationItem
							icon={faSmile}
							variant="accent"
							href="/dealership/applications?filter=PENDING"
							text={t('notifications.pending', {
								count: notifications.pending.count,
								name: notifications.pending.name,
							})}
						/>
					) : null}
					{notifications?.preapproval?.name ? (
						<NotificationItem
							icon={faTimer}
							href="/dealership/applications?filter=PREAPPROVED"
							text={t('notifications.pending', {
								count: notifications.preapproval.count,
								name: notifications?.preapproval?.name,
							})}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default NotificationButton;

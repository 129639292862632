import { LocalizationAwareObjectType } from '@/services/Hooks/useLocalizationAwareObject';

export const legalBitsList = ({ t, locale }: LocalizationAwareObjectType) => {
	switch (locale) {
		case 'es': {
			const columnList = [
				{
					name: t('legalBitsList.privacy.name'),
					path: t('legalBitsList.privacy.path'),
				},
				{
					name: t('legalBitsList.cookies.name'),
					path: t('legalBitsList.cookies.path'),
				},
				{
					name: t('legalBitsList.complaints.name'),
					path: t('legalBitsList.complaints.path'),
				},
			];
			return columnList;
		}
		case 'nl': {
			const columnList = [
				{
					name: t('legalBitsList.privacy.name'),
					path: t('legalBitsList.privacy.path'),
				},
				{
					name: t('legalBitsList.cookies.name'),
					path: t('legalBitsList.cookies.path'),
				},
			];
			return columnList;
		}
		case 'de': {
			const columnList = [
				{
					name: t('legalBitsList.collections.name'),
					path: t('legalBitsList.collections.path'),
				},
				{
					name: t('legalBitsList.complaints.name'),
					path: t('legalBitsList.complaints.path'),
				},
				{
					name: t('legalBitsList.privacy.name'),
					path: t('legalBitsList.privacy.path'),
				},
				{
					name: t('legalBitsList.codeOfConduct.name'),
					path: t('legalBitsList.codeOfConduct.path'),
				},
				{
					name: t('legalBitsList.cookies.name'),
					path: t('legalBitsList.cookies.path'),
				},
			];
			return columnList;
		}
		case 'ie': {
			const columnList = [
				{
					name: t('legalBitsList.collections.name'),
					path: t('legalBitsList.collections.path'),
				},
				{
					name: t('legalBitsList.complaints.name'),
					path: t('legalBitsList.complaints.path'),
				},
				{
					name: t('legalBitsList.privacy.name'),
					path: t('legalBitsList.privacy.path'),
				},
				{
					name: t('legalBitsList.responsible.name'),
					path: t('legalBitsList.responsible.path'),
				},
				{
					name: t('legalBitsList.cookies.name'),
					path: t('legalBitsList.cookies.path'),
				},
			];
			return columnList;
		}
		default: {
			const columnList = [
				{
					name: t('legalBitsList.collections.name'),
					path: t('legalBitsList.collections.path'),
				},
				{
					name: t('legalBitsList.complaints.name'),
					path: t('legalBitsList.complaints.path'),
				},
				{
					name: t('legalBitsList.privacy.name'),
					path: t('legalBitsList.privacy.path'),
				},
				{
					name: t('legalBitsList.responsible.name'),
					path: t('legalBitsList.responsible.path'),
				},
				{
					name: t('legalBitsList.cookies.name'),
					path: t('legalBitsList.cookies.path'),
				},
				{
					name: t('legalBitsList.terms.name'),
					path: t('legalBitsList.terms.path'),
				},
			];
			return columnList;
		}
	}
};

export const forDriversList = ({ t, locale }: LocalizationAwareObjectType) => {
	switch (locale) {
		case 'es': {
			const columnList = [
				{
					name: t('forDriversList.howBumperWorks.name'),
					path: t('forDriversList.howBumperWorks.path'),
				},
				{
					external: true,
					name: t('forDriversList.careers.name'),
					path: 'https://bumper-1692709783.teamtailor.com/',
				},
			];
			return columnList;
		}
		case 'nl': {
			const columnList = [
				{
					name: t('forDriversList.howBumperWorks.name'),
					path: t('forDriversList.howBumperWorks.path'),
				},
			];
			return columnList;
		}
		case 'de': {
			const columnList = [
				{
					name: t('forDriversList.howBumperWorks.name'),
					path: t('forDriversList.howBumperWorks.path'),
				},
				{
					external: true,
					name: t('forDriversList.careers.name'),
					path: 'https://bumper-1692709783.teamtailor.com/',
				},
				{
					name: t('forDriversList.impressum.name'),
					path: t('forDriversList.impressum.path'),
				},
			];
			return columnList;
		}
		case 'gb': {
			const columnList = [
				{
					name: t('forDriversList.findAPartner.name'),
					path: t('forDriversList.findAPartner.path'),
				},
				{
					name: t('forDriversList.howBumperWorks.name'),
					path: t('forDriversList.howBumperWorks.path'),
				},
				{
					name: t('forDriversList.typicalCost.name'),
					path: t('forDriversList.typicalCost.path'),
				},
				{
					name: t('forDriversList.moneyWorries.name'),
					path: t('forDriversList.moneyWorries.path'),
				},
			];
			return columnList;
		}
		default: {
			const columnList = [
				{
					name: t('forDriversList.findAPartner.name'),
					path: t('forDriversList.findAPartner.path'),
				},
				{
					name: t('forDriversList.howBumperWorks.name'),
					path: t('forDriversList.howBumperWorks.path'),
				},
				{
					name: t('forDriversList.typicalCost.name'),
					path: t('forDriversList.typicalCost.path'),
				},
				{
					name: t('forDriversList.moneyWorries.name'),
					path: t('forDriversList.moneyWorries.path'),
				},
			];
			return columnList;
		}
	}
};

export const forBusinessList = ({ t }: LocalizationAwareObjectType) => {
	const columnList = [
		{
			name: t('forBusinessList.becomePartner.name'),
			path: t('forBusinessList.becomePartner.path'),
		},
		{
			name: t('forBusinessList.partnerLogin.name'),
			path: t('forBusinessList.partnerLogin.path'),
		},
	];

	return columnList;
};
export const bumperList = ({ t, locale }: LocalizationAwareObjectType) => {
	switch (locale) {
		case 'gb': {
			const columnList = [
				{
					name: t('bumperList.aboutUs.name'),
					path: t('bumperList.aboutUs.path'),
				},
				{
					external: true,
					name: t('bumperList.careers.name'),
					path: 'https://bumper-1692709783.teamtailor.com/',
				},
				{
					name: t('bumperList.sustainability.name'),
					path: t('bumperList.sustainability.path'),
				},
				{
					name: t('bumperList.areasWeServe.name'),
					path: t('bumperList.areasWeServe.path'),
				},
				{
					name: t('bumperList.bumperBlog.name'),
					path: t('bumperList.bumperBlog.path'),
				},
			];
			return columnList;
		}
		default: {
			const columnList = [
				{
					name: t('bumperList.bumperBlog.name'),
					path: t('bumperList.bumperBlog.path'),
				},
				{
					external: true,
					name: t('bumperList.careers.name'),
					path: 'https://bumper-1692709783.teamtailor.com/',
				},
				{
					name: t('bumperList.sustainability.name'),
					path: t('bumperList.sustainability.path'),
				},
			];
			return columnList;
		}
	}
};

export const partnersList = ({ t }: LocalizationAwareObjectType) => {
	const columnList = [
		{
			name: t('partnersList.becomePartner.name'),
			path: t('partnersList.becomePartner.path'),
		},
		{
			name: t('partnersList.partnerLogin.name'),
			path: t('partnersList.partnerLogin.path'),
		},
	];

	return columnList;
};
export const resourcesList = ({ t, locale }: LocalizationAwareObjectType) => {
	switch (locale) {
		case 'gb': {
			const columnList = [
				{
					name: t('resourcesList.typicalCost.name'),
					path: t('resourcesList.typicalCost.path'),
				},
				{
					name: t('resourcesList.bumperBlog.name'),
					path: t('resourcesList.bumperBlog.path'),
				},
				{
					name: t('resourcesList.moneyWorries.name'),
					path: t('resourcesList.moneyWorries.path'),
				},
			];
			return columnList;
		}
		case 'ie': {
			const columnList = [
				{
					name: t('resourcesList.typicalCost.name'),
					path: t('resourcesList.typicalCost.path'),
				},
				{
					name: t('resourcesList.bumperBlog.name'),
					path: t('resourcesList.bumperBlog.path'),
				},
				{
					name: t('resourcesList.moneyWorries.name'),
					path: t('resourcesList.moneyWorries.path'),
				},
			];
			return columnList;
		}
		default: {
			const columnList = [
				{
					name: t('resourcesList.bumperBlog.name'),
					path: t('resourcesList.bumperBlog.path'),
				},
			];
			return columnList;
		}
	}
};

import { parseCookies } from 'nookies';
import { FC } from 'react';
import { StatsigProvider as StatsigReactProvider } from 'statsig-react';

import { STATSIG_USER_COOKIE } from '@/constants/statsig';

const StatsigProvider: FC = ({ children }) => {
	const cookies = parseCookies();
	const userID = cookies[STATSIG_USER_COOKIE];
	return (
		<StatsigReactProvider
			sdkKey={`${process.env.NEXT_PUBLIC_STATSIG_CLIENT_ID}`}
			waitForInitialization={false}
			user={{ userID }}
			options={{ environment: { tier: `${process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT}` } }}
		>
			{children}
		</StatsigReactProvider>
	);
};

export default StatsigProvider;

// eslint-disable-next-line check-file/folder-naming-convention
import { faCaretDown, faCaretUp, faUserSlash } from '@fortawesome/pro-solid-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useRef, useState } from 'react';

import Icon from '@/components/Icon';
import { useUser } from '@/services/Hooks/useUser';

import { IUserButton } from './index.d';

const UserButton: FC<IUserButton.IProps> = ({ isMobile = false }) => {
	const { t } = useTranslation('navbar');
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { userDetails } = useUser();
	const router = useRouter();
	const [isActive, setIsActive] = useState<boolean>(false);
	const { logout } = useUser();

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (isActive && wrapperRef?.current && !wrapperRef.current?.contains(e.target)) {
				setIsActive(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [isActive]);

	const handleUserClick = () => {
		setIsActive((dState) => !dState);
	};

	const signOut = async () => {
		const result = await logout();
		if (result) {
			router.push('/');
		} else {
			// TODO: Logout is unsuccessfull
		}
	};

	return (
		<div
			tabIndex={0}
			role="button"
			onKeyDown={handleUserClick}
			ref={wrapperRef}
			className="items-end justify-end relative py-1 mr-1 group cursor-pointer"
			onClick={handleUserClick}
		>
			<span className="flex flex-row items-center space-x-0.5 text-primary-content">
				<span>
					{userDetails?.name || userDetails?.username
						? `${t('hi')} ${userDetails?.name ? userDetails?.name : userDetails?.username}`
						: t('hi')}
				</span>
				<Icon iconName={isActive ? faCaretUp : faCaretDown} />
			</span>
			{!isMobile ? (
				<span
					className={`absolute h-1 left-0 ${
						isActive ? 'bg-nav-content' : 'bg-transparent group-hover:bg-nav-contrast'
					} bottom-0 w-full rounded-t-2xl`}
				/>
			) : null}
			{isActive ? (
				<div
					className={`bg-tertiary-content absolute left-0 w-full top-14 flex flex-row space-x-1 ${
						isMobile ? 'rounded-3xl' : 'rounded-b-2xl'
					} px-1`}
				>
					<div
						onKeyDown={signOut}
						role="button"
						tabIndex={-1}
						onClick={signOut}
						className="p-1 w-full text-center text-n0 text-tertiary whitespace-nowrap flex flex-col relative group"
					>
						<div className="flex flex-row justify-center items-center space-x-0.5">
							<span>{t('logout')}</span>
							<Icon iconName={faUserSlash} variant="tertiary-reverse" />
						</div>
						{!isMobile ? (
							<span className="absolute left-0 bg-transparent group-hover:bg-reverse bottom-0 h-1 w-full rounded-t-2xl" />
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};
export default UserButton;

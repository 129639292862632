import { IMarketplaceApplicationStore } from '@/redux/StateSlices/MarketplaceApplication/index.d';

export const MarketplaceApplicationInitialState: IMarketplaceApplicationStore.IState = {
	request: {
		address: {
			buildingname: '',
			buildingnumber: '',
			country: '',
			countryname: '',
			county: '',
			postcode: '',
			street: '',
			subbuildingname: '',
			town: '',
		},
		affiliate_src: 'asfwebsite',
		amount: '0',
		brand: '',
		creditconsent: false,
		dealership: 'generic',
		dob: '',
		email: '',
		forename: '',
		marketing_options: {
			mail: false,
			post: false,
			sms: false,
		},
		mobile: '',
		name: '',
		postcode: '',
		preapproved: true,
		registration: '',
		surname: '',
	},
	result: {
		asf_code: '',
		dealership: 0,
		id: '', // sola id
		limit: 0,
		result: '',
	},
};

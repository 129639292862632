export const ADDRESS_URL = `/Capture/Interactive/Find/v1.1/json3.ws`;
export const ADDRESS_RETRIEVE_URL = `/Capture/Interactive/Retrieve/v1.2/json3.ws`;
export const CREATE_LEAD = 'core/lead/lead';
export const DEALERSHIP_APPLICATIONS = 'core/application/applications';
export const RETENTION_URL = '/core/customer/retention';

/* Application */
export const APPLICATION_URL = 'core/application';
export const IDCONSUMER = `${APPLICATION_URL}/idconsumer`;
export const ACCEPT_OFFER = `${APPLICATION_URL}/acceptoffer`;
export const APPLY = `${APPLICATION_URL}/apply`;
export const CANCEL = `${APPLICATION_URL}/cancel`;
export const INVOICE = `${APPLICATION_URL}/invoice`;
export const APPLICATION_SEND_LINK = `${APPLICATION_URL}/payment-request/apply`;
export const UPDATE = `${APPLICATION_URL}/update`;

/* Account Recovery */
export const ACCOUNT_RECOVERY = '/core/user/advisor-accountrecovery';

/* Booking */
export const GET_BOOKING_DATES_URL = '/core/dealership/available/days';
export const GET_BOOKING_HOURS_URL = '/core/dealership/available/times';
export const CREATE_BOOKING = '/core/booking/create/appointment';

/* Business Lending */
export const BUSINESS_LENDING = 'core/businesslending';
export const CREATE_ORDER_URL = `${BUSINESS_LENDING}/create_order`;
export const ORDER_INTENT_URL = `${BUSINESS_LENDING}/order_intent`;
export const ORDER_CONFIRMATION_URL = `${BUSINESS_LENDING}/confirm_order`;
export const GET_PRODUCTS_URL = `${BUSINESS_LENDING}/products`;

/* Categories */
export const GET_CATEGORIES = 'core/user/categories';

/* CDK */
export const RETRIEVE_DATA = 'core/cdk/retrievedata';

/* Country */
export const GET_MY_COUNTRY = '/core/customer/get-my-country';

/* Dashboard */
export const GET_DASHBOARD = 'core/user/dashboard';

/* Dealership */
export const DEALERSHIP_URL = 'core/dealership';
export const DEALERSHIP_DEALERSHIPS = `${DEALERSHIP_URL}/dealership`;
export const DEALERSHIP_DETAILS = `${DEALERSHIP_URL}/dealership-details`;
export const DEALERSHIP_GROUP_DEALERSHIPS = `${DEALERSHIP_URL}/group/dealerships`;
export const DEALERSHIP_STATS = `${DEALERSHIP_URL}/dealership-stats`;
export const DEALERSHIP_REGISTRATION = `${DEALERSHIP_URL}/registration`;
export const HIDE_CARD_PAYMENT = `${DEALERSHIP_URL}/card-amounts`;
export const GET_DEALERSHIP_BY_POSTCODE = `${DEALERSHIP_URL}/postcodenearby`;
export const GET_OEM_NEARBYDEALERSHIPS = `${DEALERSHIP_URL}/oemnearbydealerships`;
export const SELECT_DEALERSHIP = 'core/application/selectdealership';
export const DEALERSHIP_TRUSTPILOT = `${DEALERSHIP_URL}/trustpilot/reviews`;
export const DEALERSHIP_BRANDS = `${DEALERSHIP_URL}/brands`;
export const DEALERSHIP_SERVICE_INFORMATION = `${DEALERSHIP_URL}/ramstein`;

/* Increase Paylater */
export const SENDPAYLATERTOCUSTOMER = 'core/application/send_increase_paylater_request';
export const INCREASEPAYLATERAUTHORISE =
	'core/payment/payment/paylater/increase-pay-later-authorise';

/* Instalments */
export const GET_INSTALMENTS = 'core/application/product/getinstalments';
export const UPDATED_INSTALMENTS = 'core/application/update/getinstalments';
export const REPAYMENT_INSTALMENTS = 'core/application/onlinerepayment';

/* Integration */
export const INTEGRATION_DETAILS = '/core/application/payment-request/detail';

/* Notification */
export const GET_NOTIFICATIONS = 'core/notification/notification';

/* Onfido */
export const ONFIDO_IDV = 'core/idv';
export const ONDIDO_ABORT = `${ONFIDO_IDV}/abort`;
export const ONFIDO_SDK_TOKEN = `${ONFIDO_IDV}/create/applicant`;
export const ONFIDO_CHECK_STATUS = `${ONFIDO_IDV}/check/status`;
export const ONFIDO_UPLOAD_DOCUMENTS = `${ONFIDO_IDV}/create/document`;

/* Payment */
export const PAYLATER_AUTH = 'core/payment/payment/paylater/authorize';
export const CUSTOMER_3DSONE_DETAIL = `${PAYLATER_AUTH}/3dsone`;
export const CUSTOMER_3DS2_DETAILS = `${PAYLATER_AUTH}/details`;
export const PAYMENT_SUBSCRIPTION = 'core/payment/subscription/authorize';
export const SUBSCRIPTION_3DS1_DETAILS = `${PAYMENT_SUBSCRIPTION}/3dsone`;
export const SUBSCRIPTION_3DS2_DETAILS = `${PAYMENT_SUBSCRIPTION}/details`;
export const SUBSCRIPTION_PAYMENT_TYPE = 'core/subscription/psp';
export const MANGOPAY_APPLE_SESSION = 'core/payment/mangopay/appletoken';
export const GET_PAYMENT_METHODS = 'core/payment/payment/methods';
export const MANGOPAY_PAYNOW_CARD_REGISTRATION = 'core/payment/mangopay/card-registration/';
export const MANGOPAY_PAYNOW_TAKE_CARD_PAYMENT = 'core/payment/mangopay/takepayment/card/';
export const MANGOPAY_PAYNOW_TAKE_GOOGLEPAY_PAYMENT = '/core/payment/mangopay/takepayment/google/';
export const MANGOPAY_PAYNOW_TAKE_APPLEPAY_PAYMENT = '/core/payment/mangopay/takepayment/apple/';
export const MANGOPAY_PAYMENT_METHODS = 'core/payment/payment/methods-mango-pay';

/* Paynow */
export const CREATE_PAYNOW = 'core/paynow/create';

/* Products */
export const GET_PRODUCTS = 'core/user/products';

/* Recaptcha */
export const VERIFY_RECAPTCHA = '/recaptcha/api/siteverify';

/* Barclay */
export const BARCLAY_PAYMENT_METHODS = 'core/payment/payment/methods-barclay-card';
export const GET_PAYMENT_TYPE = 'core/application/psp';
export const GET_PAYNOW_PAYMENT_TYPE = 'core/dealership/paynow-psp';

/* Open Banking */
export const OPEN_BANKING = '/core/payment/ob/thirdparty';
export const OPEN_BANKING_TAKE_PAYMENT = `${OPEN_BANKING}/takepayment`;
export const OPEN_BANKING_PAYMENT_STATUS = `${OPEN_BANKING}/paymentstatus`;
export const OPEN_BANKING_PAYMENT_DETAILS = '/core/payment/payment/detail';

/* Smartfuse */
export const SMARTFUSE_URL = 'core/payment/smartfuse';
export const REFUND_AMOUNT = `${SMARTFUSE_URL}/thirdparty/refund`;
export const SMARTFUSE_PA_TOKEN = `${SMARTFUSE_URL}/patoken/`;
export const SMARTFUSE_PAYMENT = `${SMARTFUSE_URL}/thirdparty/takepayment/`;
export const APPLE_PAY_MERCHANT_SESSION = `${SMARTFUSE_URL}/appletoken/`;
export const SMARTFUSE_CC_TOKEN = `${SMARTFUSE_URL}/cctoken`;

/* Online Repay */
export const ONLINE_REPAY_AUTH = 'core/payment/payment/onlinerepayment/authorize';

/* Reset Password */
export const RESET_PASSWORD = '/core/user/resetpassword';

/* Rewards */
export const GET_REWARDS = 'core/user/reward';
export const BRANDS = '/core/user/brands';

/* Subscription */
export const SUBSCRIPTION_URL = '/core/subscription';
export const GET_RAMP_PRODUCTS = `${SUBSCRIPTION_URL}/dealership/ramp-products`;
export const SEND_LINK = `${SUBSCRIPTION_URL}/create`;

/* Terms and Conditions */
export const TERMS_AND_CONDITIONS = 'core/application/tac';

/* User */
export const GET_API_LAST_LOGIN = 'core/user/api-last-login';
export const USER_DETAILS = 'core/user/userdetail';

/* Auth */
export const AUTH_ACCESS_TOKEN = 'core/customer/api-token-obtain';
export const AUTH_REFRESH_TOKEN = 'core/customer/api-token-refresh';
export const AUTH_LOGOUT = 'core/customer/api-logout';

/* Vehicle */
export const VEHICLE_REGISTRATION_INFO = 'core/vehicle/info/';

import { AUTH_LOGOUT } from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';

import { IAuthApi } from './index.d';

export const AuthApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		logout: builder.query<IAuthApi.IResponse, void>({
			query: () => ({
				method: 'GET',
				url: AUTH_LOGOUT,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useLazyLogoutQuery } = AuthApi;

/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const OnlineRepay = createSlice({
	initialState: {},
	name: 'onlineRepayState',
	reducers: {
		setOnlineRepayResult: (state, action: PayloadAction<any>) => ({
			...action.payload,
		}),
	},
});

export const onlineRepaySelector = (state: any) => state[OnlineRepay.name];

export const { setOnlineRepayResult } = OnlineRepay.actions;
export const onlineRepayName = OnlineRepay.name;
export const onlineRepayReducer = OnlineRepay.reducer;

export default OnlineRepay;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	FALLBACK_THEME,
	getCorrectThemeConfiguration,
	ITheme,
	themeConfigurations,
} from '@/services/Providers/Theme';

const initialState: ITheme = { ...themeConfigurations[FALLBACK_THEME] };

export const Theme = createSlice({
	initialState,
	name: 'theme',
	reducers: {
		changeTheme: (state, action: PayloadAction<string | any>) => {
			const { loginBackground, logo, name, readableName, marketing } = getCorrectThemeConfiguration(
				action.payload
			);
			state.loginBackground = loginBackground;
			state.logo = logo;
			state.name = name;
			state.readableName = readableName;
			state.marketing = marketing;
		},
	},
});

export const themeReducerName = Theme.name;
export const themeReducer = Theme.reducer;

export const { changeTheme } = Theme.actions;
export const selectTheme = (state: any) =>
	state[Theme.name].name || themeConfigurations[FALLBACK_THEME].name;
export const activeThemeSelector = (state: any) =>
	state[Theme.name] || themeConfigurations[FALLBACK_THEME];

export default Theme.reducer;

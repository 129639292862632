// eslint-disable-next-line check-file/folder-naming-convention
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Icon from '@/components/Icon';
import DropdownMenu from '@/components/Navbar/DropdownMenu';
import { IMobileLinkItem } from '@/components/Navbar/MobileLinkItem/index.d';
import { Body } from '@/components/Text';

const MobileLinkItem: FC<IMobileLinkItem.IProps> = ({
	href: hrefLink,
	name,
	icon,
	border,
	blackList,
	dropdownLinks,
	activeLinks,
	user,
}) => {
	const { t } = useTranslation('navbar');
	const router = useRouter();
	const { locale, pathname } = router;
	let href = hrefLink;
	if (href === '/dealership/dashboard' && !!user?.user_rewards) {
		href = '/dealership/dashboard?newrewards=true';
	}
	const isActive = pathname === href;

	const isDropdownActive = dropdownLinks && activeLinks?.includes(router.pathname);
	// eslint-disable-next-line no-nested-ternary
	const caretIcon = dropdownLinks ? (isActive ? faCaretUp : faCaretDown) : undefined;
	const linkIcon = icon || caretIcon;

	if (locale && blackList?.includes(locale)) {
		return null;
	}

	return (
		<Link href={href}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a
				className={` cursor-pointer relative ${
					border ? 'w-fit border rounded-full py-0.5 px-0.75' : 'flex flex-col py-1'
				}`}
			>
				<span className="group">
					<Body className="whitespace-nowrap items-center space-x-0.5">
						<span className="text-nav-content">{t(name)}</span>
						{linkIcon ? <Icon iconName={linkIcon} /> : null}
					</Body>
					{!border ? (
						<span
							className={`${
								isActive || isDropdownActive
									? 'bg-tone-contrast'
									: 'bg-transparent group-hover:bg-reverse'
							} absolute -left-6 bottom-0 h-full w-2 rounded-r-2xl`}
						/>
					) : null}
				</span>
				{isDropdownActive && dropdownLinks && dropdownLinks.length > 0 ? (
					<DropdownMenu dropdownLinks={dropdownLinks} isMobile user={user} />
				) : null}
			</a>
		</Link>
	);
};

export default MobileLinkItem;

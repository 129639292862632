import { createSlice } from '@reduxjs/toolkit';

import { BookingInitialState as initialState } from './initial';

const Booking = createSlice({
	initialState,
	name: 'booking',
	reducers: {
		setApplyResult(state, action) {
			state.applyResult = action.payload;
		},
		setBookingData: (state, action) => {
			state.booking = action.payload;
		},
	},
});
export const bookingReducerName = Booking.name;
export const bookingReducer = Booking.reducer;
export const { setBookingData, setApplyResult } = Booking.actions;

export default Booking;

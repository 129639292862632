import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { FunctionComponent } from 'react';

import Container from '@/components/Container';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import Copyright from '@/modules/Footer/Copyright';
import FooterColumn from '@/modules/Footer/FooterColumn';
import FooterContact from '@/modules/Footer/FooterContact';
import FooterLinks from '@/modules/Footer/FooterLinks';
import { useLocalizationAwareObject } from '@/services/Hooks/useLocalizationAwareObject';
import {
	bumperList,
	forBusinessList,
	forDriversList,
	legalBitsList,
	partnersList,
	resourcesList,
} from '@/services/Utils/Locale/Footer/ColumnList';

const Footer: FunctionComponent = () => {
	const { t } = useTranslation('footer');
	const { locale } = useRouter();
	const legalBitsColumnList = useLocalizationAwareObject(legalBitsList, 'footer', locale);
	const forDriversColumnList = useLocalizationAwareObject(forDriversList, 'footer', locale);
	const forBusinessColumnList = useLocalizationAwareObject(forBusinessList, 'footer', locale);
	const bumperColumnList = useLocalizationAwareObject(bumperList, 'footer', locale);
	const partnersColumnList = useLocalizationAwareObject(partnersList, 'footer', locale);
	const resourcesColumnList = useLocalizationAwareObject(resourcesList, 'footer', locale);

	const renderFooterLinks = () => {
		if (locale !== IRELAND) {
			if (locale === UNITED_KINGDOM) {
				return (
					<>
						<FooterColumn menuItems={forDriversColumnList} title={t('forDrivers')} />
						<FooterColumn menuItems={forBusinessColumnList} title={t('forBusiness')} />
						<FooterColumn menuItems={bumperColumnList} title={t('bumperTitle')} />
					</>
				);
			}
			return (
				<>
					<FooterColumn menuItems={bumperColumnList} title={t('bumperTitle')} />
					<FooterColumn menuItems={partnersColumnList} title={t('partners')} />
					<FooterColumn menuItems={resourcesColumnList} title={t('resources')} />
				</>
			);
		}
		return <div className="hidden lg:block lg:col-span-4 " />;
	};

	return (
		<Container variant="primary" className="py-2">
			<div className="bottom-0 inset-x-0 flex-1 bg-primary">
				<div className="pt-2.5 pb-4 lg:pb-8">
					<div className="block md:flex border-b border-primary-contrast pb-2.5">
						<FooterLinks />
						<div className="grid grid-cols-4 gap-x-1.5 lg:flex lg:justify-between lg:flex-1">
							{renderFooterLinks()}
							<FooterColumn menuItems={legalBitsColumnList} title={t('legalBits')} />
						</div>
					</div>
					<FooterContact />
					<Copyright />
				</div>
			</div>
		</Container>
	);
};
export default Footer;

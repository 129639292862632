import { TFunction, useTranslation } from 'next-i18next';
import React from 'react';

export type LocalizationAwareObjectType = {
	t: TFunction;
	locale?: string;
};

// eslint-disable-next-line no-unused-vars
type Generator<T extends {}> = ({ t, locale }: LocalizationAwareObjectType) => T;

const useLocalizationAwareObject = <T extends {}>(
	fn: Generator<T>,
	ns?: string,
	locale?: string
) => {
	const { t } = useTranslation(ns);
	return React.useMemo(() => fn({ locale, t }), [fn, t, locale]);
};

// eslint-disable-next-line import/prefer-default-export
export { useLocalizationAwareObject };

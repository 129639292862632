enum USER_GROUPS {
	VISITOR = 'visitor',
	STAFF = 'staff',
}

declare namespace IAuth {
	interface AccessToken {
		user_id: number;
		username: string;
		email: string;
		user_groups?: USER_GROUPS;
		exp: number;
		orig_iat: number;
		reset_password?: boolean;
	}

	interface ISignOut {
		isRefreshTokenError?: boolean;
	}
}

export { IAuth, USER_GROUPS };

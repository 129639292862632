// eslint-disable-next-line check-file/folder-naming-convention
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { IAuthLink } from '@/components/Navbar/DesktopNavbar/index.d';
import { ILinkItem } from '@/components/Navbar/LinkItem/index.d';
import MobileLinkItem from '@/components/Navbar/MobileLinkItem';
import { IMobileAuthLink, IMobileNavbar } from '@/components/Navbar/MobileNavbar/index.d';
import NotificationButton from '@/components/Navbar/NotificationButton';
import UserButton from '@/components/Navbar/UserButton';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import { UnauthenticatedLinks } from '@/constants/navbar';
import { useUser } from '@/services/Hooks/useUser';

const AuthenticatedAuthLinks: FC<IMobileAuthLink.IProps> = () => (
	<>
		<UserButton isMobile />
	</>
);

const UnAuthenticatedAuthLinks: FC<IAuthLink.IProps> = ({ hide, homepageType }) => {
	const { push } = useRouter();
	const { t } = useTranslation('navbar');
	if (hide) return null;
	const isDriverPage = homepageType === 'for-drivers';
	return (
		<>
			<Button
				onClick={() => push(isDriverPage ? '/locations' : '/registration/dealership')}
				label={isDriverPage ? t('apply') : t('signUp')}
				structure="small"
				textClassName="text-sm font-sans"
				size="medium"
				type="button"
			/>
		</>
	);
};

const MobileNavbar: FC<IMobileNavbar.IProps> = ({
	logo,
	authenticated,
	hideAuthLinks,
	user,
	VisibleAuthenticatedLinks,
	homepageType,
}) => {
	const [showMobile, setShowMobile] = useState<boolean>(false);
	const { notifications } = useUser();
	const { locale = UNITED_KINGDOM, events } = useRouter();
	const shouldShowBusinessAndDriversTabs =
		[UNITED_KINGDOM, IRELAND].includes(locale) && homepageType !== 'international';

	const handleToggleMenu = () => {
		setShowMobile((dState) => !dState);
	};
	useEffect(() => {
		const handleRouteChange = () => {
			setShowMobile(false);
		};
		events.on('routeChangeStart', handleRouteChange);
		return () => {
			events.off('routeChangeStart', handleRouteChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<div className="flex h-full z-10 w-full">
				<div className="w-full px-1 py-0.75 flex flex-row justify-between items-center">
					<Link href={authenticated ? '/dealership/application' : '/'}>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a className="flex items-center cursor-pointer pb-0.25">
							<Image
								client
								src={logo.src}
								height={logo.height || 0}
								width={logo.width || 0}
								alt="logo"
							/>
							{shouldShowBusinessAndDriversTabs ? (
								<p className="ml-0.5">{homepageType.replace(/-/g, ' ')}</p>
							) : (
								''
							)}
						</a>
					</Link>

					<div className="flex flex-row space-x-0.5">
						{notifications && authenticated ? <NotificationButton /> : null}
						{homepageType === 'for-business' && !authenticated ? null : (
							<button
								type="button"
								className={`btn btn-ghost border rounded-full h-8 w-8 flex items-center justify-center ${
									showMobile ? 'bg-tertiary' : ''
								}`}
								onClick={handleToggleMenu}
							>
								<Icon iconName={showMobile ? faTimes : faBars} />
							</button>
						)}
						{homepageType === 'for-business' && !authenticated ? (
							<UnAuthenticatedAuthLinks hide={hideAuthLinks} homepageType={homepageType} />
						) : null}
					</div>
				</div>
			</div>
			{showMobile ? (
				<div className="absolute left-0 w-full h-screen bg-primary z-0 pt-4">
					<div className="flex flex-col space-y-0.5 px-1.5">
						{(authenticated ? VisibleAuthenticatedLinks : UnauthenticatedLinks).map(
							(link: ILinkItem.IProps, index: number) => (
								<MobileLinkItem
									blackList={link.blackList}
									key={`${String(index)}-${link.name}`}
									href={link.href}
									icon={link.icon}
									name={link.name}
									dropdownLinks={link.dropdownLinks}
									activeLinks={link.activeLinks}
									user={user}
								/>
							)
						)}
					</div>
					<div className="flex flex-col border-t mt-0.5 pt-1 px-1.5 space-y-1">
						{authenticated ? (
							<AuthenticatedAuthLinks />
						) : (
							<UnAuthenticatedAuthLinks hide={hideAuthLinks} homepageType={homepageType} />
						)}
					</div>
				</div>
			) : null}
		</>
	);
};

export default MobileNavbar;

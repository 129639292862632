import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDealershipApi } from '@/redux/APISlices/DealershipAPI/index.d';
import { IApplyApplicationStore } from '@/redux/StateSlices/Apply/index.d';
import { ApplicationApplyState as initialState } from '@/redux/StateSlices/Apply/initial';

const ApplicationApply = createSlice({
	initialState,
	name: 'applicationApply',
	reducers: {
		resetState() {
			return {
				...initialState,
			};
		},

		setDealershipDetail: (
			state,
			action: PayloadAction<IDealershipApi.IGetDealershipDetailsResponse | null>
		) => {
			state.dealershipDetail = action.payload;
		},
		setInstallments: (state, action: PayloadAction<IApplyApplicationStore.IInstallment | null>) => {
			// TODO: will be added type for action
			state.installments = action.payload;
		},

		setRequest: (state, action: PayloadAction<IApplyApplicationStore.IApplyRequest>) => {
			state.applyRequest = action.payload;
		},
		setResult: (state, action: PayloadAction<IApplyApplicationStore.IApplyResult>) => {
			state.applyResult = action.payload;
		},
		setVehicle: (state, action: PayloadAction<IApplyApplicationStore.IVehicle | null>) => {
			state.vehicle = action.payload;
		},
	},
});
export const applicationApplyReducerName = ApplicationApply.name;
export const applicationApplyReducer = ApplicationApply.reducer;
export const {
	resetState,
	setRequest,
	setResult,
	setDealershipDetail,
	setVehicle,
	setInstallments,
} = ApplicationApply.actions;

export default ApplicationApply;

export const UNITED_KINGDOM: string = 'gb';
export const IRELAND: string = 'ie';
export const GERMANY: string = 'de';
export const SPAIN: string = 'es';
export const PORTUGAL: string = 'pt';
export const NETHERLANDS: string = 'nl';

export type countryCode = 'de' | 'ie' | 'gb' | 'es' | 'nl';
export const ADDRESS_SEARCH_COUNTRIES = 'GB,IE,DE,ES,PT,NL';

export const LOCALE_LIST: string[] = [
	UNITED_KINGDOM,
	IRELAND,
	GERMANY,
	SPAIN,
	PORTUGAL,
	NETHERLANDS,
];

export const COUNTRY_OPTION_LIST = [
	{
		countryCode: 'de',
		id: 1,
		img: {
			height: 18,
			src: 'images/flags/de_rect.svg',
			width: 24,
		},
		name: 'Deutschland',
	},
	{
		countryCode: 'nl',
		id: 2,
		img: {
			height: 18,
			src: 'images/flags/nl_rect.svg',
			width: 24,
		},
		name: 'Netherlands',
	},
	{
		countryCode: 'ie',
		id: 3,
		img: {
			height: 18,
			src: 'images/flags/ie_rect.svg',
			width: 24,
		},
		name: 'Republic of Ireland',
	},
	{
		countryCode: 'gb',
		id: 4,
		img: {
			height: 18,
			src: 'images/flags/gb_rect.svg',
			width: 24,
		},
		name: 'United Kingdom',
	},
	{
		countryCode: 'es',
		id: 5,
		img: {
			height: 18,
			src: 'images/flags/es_rect.svg',
			width: 24,
		},
		name: 'España',
	},
];

export interface IRedirectPaths {
	[key: string]: string;
}

export const REDICRECT_PATHS: IRedirectPaths[] = [
	{
		de: '/privatsphare',
		es: '/privacidad',
		gb: '/privacy',
		ie: '/privacy',
		nl: '/privacybeleid',
	},
	{
		de: '/404',
		es: '/404',
		gb: '/locations',
		ie: '/404',
		nl: '/404',
	},
];

export const getRegexPatterns = (locale: string, isDealershipRamstein: boolean) => {
	if (isDealershipRamstein) {
		return {
			contactNumber: /^(0?1?[2-9][0-9]{4,12})$/,
			maxPhoneLength: 12,
			mobile: /^0(\s*)(1)(\s*)(\d(\s*)){9,10}$/,
			postCode: {
				full: /^.*$/,
				partial: /^.*$/,
			},
			vehicleReg: /.*/,
		};
	}

	switch (locale) {
		case 'ie':
			return {
				contactNumber: /^0(\s*)(8)(\s*)(\d(\s*)){8}$/,
				maxPhoneLength: 11,
				mobile: /^0(\s*)(8)(\s*)(\d(\s*)){8}$/,
				postCode: {
					full: /^(?:\s*[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}\s*|(?:\s*[ac-fhknprtv-y][0-9]{2}|d6w)[ -]?[0-9ac-fhknprtv-y]{4}\s*$/,
					partial:
						/^(?:\s*[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}\s*|(?:\s*[ac-fhknprtv-y][0-9]{2}|d6w)[ -]?[0-9ac-fhknprtv-y]{4}\s*$/,
				},
				vehicleReg: /^([A-Za-z0-9]\s*){1,11}$/,
			};
		case 'de':
			return {
				contactNumber: /^(0?1?[2-9][0-9]{4,12})$/,
				maxPhoneLength: 12,
				mobile: /^0(\s*)(1)(\s*)(\d(\s*)){9,10}$/,
				postCode: {
					full: /^.*$/,
					partial: /^.*$/,
				},
				vehicleReg: /.*/,
			};
		case 'es':
			return {
				contactNumber:
					/^(6(([0-9]{8})|([0-9]{2}[0-9]{6})|([0-9]{2}[0-9]{3}[0-9]{3}))|9(([0-9]{8})|([0-9]{2}[0-9]{6})|([1-9][0-9]{7})|([0-9]{2}[0-9]{3}[0-9]{3})|([0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2})))$/,
				maxPhoneLength: 9,
				mobile: /^(?:6[0-9]{2}|7[1-9][0-9])(?:[0-9]{3}){2}\r?$/gm,
				postCode: {
					full: /^.*$/,
					partial: /^.*$/,
				},
				vehicleReg: /^([A-Za-z]{1,2}\d{4,6}[A-Za-z]{0,3}|[0-9]{4}[A-Za-z]{3})$/,
			};
		case 'nl':
			return {
				contactNumber: /^0\d{9}$/,
				maxPhoneLength: 10,
				mobile: /^0\d{9}$/,
				postCode: {
					full: /^\d{4} ?[A-Z]{2}$/,
					partial: /^\d{4} ?[A-Z]{2}$/,
				},
				vehicleReg:
					/(\w{2}-\d{2}-\d{2})|(\d{2}-\d{2}-\w{2})|(\d{2}-\w{2}-\d{2})|(\w{2}-\d{2}-\w{2})|(\w{2}-\w{2}-\d{2})|(\d{2}-\w{2}-\w{2})|(\d{2}-\w{3}-\d{1})|(\d{1}-\w{3}-\d{2})|(\w{2}-\d{3}-\w{1})|(\w{1}-\d{3}-\w{2})|(\w{3}-\d{2}-\w{1})|(\d{1}-\w{2}-\d{3})/gm,
			};
		default:
			return {
				contactNumber: /^0(\s*)(7)(\s*)(\d(\s*)){9}$/,
				maxPhoneLength: 11,
				mobile: /^0(\s*)(7)(\s*)(\d(\s*)){9}$/,
				postCode: {
					full: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})\s*$/,
					partial:
						/^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y])))( {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2})?))\s*$/,
				},
				vehicleReg:
					/^([A-Za-z]{2}[0-9]{2}[A-Za-z]{3}$|[A-Za-z][0-9]{1,3}[A-Za-z]{3}$|[A-Za-z]{3}[0-9]{1,3}[A-Za-z]$|[0-9]{1,4}[A-Za-z]{1,2}$|[0-9]{1,3}[A-Za-z]{1,3}$|[A-Za-z]{1,2}[0-9]{1,4}$|[A-Za-z]{1,3}[0-9]{1,3}$|[A-Za-z]{1,3}[0-9]{1,4}$|[0-9]{3}[DX]{1}[0-9]{3}$|[jJ]{1}[0-9]{1,6}$|[0-9]{1,5}$)$/,
				// vehicleReg: /^([A-Za-z0-9]\s*){1,11}$/
			};
	}
};

import { IMarketplaceMapStore } from '@/redux/StateSlices/MarketplaceMap/index.d';

export const MarketplaceMapinitialState: IMarketplaceMapStore.IState = {
	creditLimit: 0,
	currentPage: 1,
	dealerships: null,
	fullMap: false,
	hoveredPartner: null,
	isMainDealershipActive: true,
	mapCenterPoint: { lat: 55.9516888, lng: -3.1880459 },
	scrolledPartner: null,
	selectedPartner: null,
	showPinNames: true,
	showSearch: false,
	tokenFlag: false,
	userLocation: {
		lat: 0,
		lng: 0,
	},
	vehicle: {
		brand: {
			brand_picture: '',
			id: 0,
			name: '',
		},
		colour: '',
		dataType: 'unset',
		installmentRange: '',
		manufactureYear: '',
		model: '',
		motStatus: '',
		motValidDate: '',
		picture: '',
		registration: '',
		roadTaxDate: '',
	},
	zoomLevel: 11,
};

import { REDICRECT_PATHS, UNITED_KINGDOM } from '@/constants/locales';
import { IGetRedirectPathTypes } from '@/services/Utils/Routing/getRedirectPath/index.d';

const getRedirectPath = (params: IGetRedirectPathTypes.IGetRedirectPath): string => {
	const { path, currentLocale = UNITED_KINGDOM, redirectLocale = UNITED_KINGDOM } = params;
	const foundPath = REDICRECT_PATHS.find((redirectPath) =>
		path.startsWith(redirectPath[currentLocale])
	);
	return foundPath && foundPath[redirectLocale] ? foundPath[redirectLocale] : path;
};

export default getRedirectPath;

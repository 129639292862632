import Script from 'next/script';
import { FC } from 'react';

import { publish } from '@/services/Events';

const TrustpilotScript: FC<{}> = () => {
	const onReady = () => {
		publish('TrustpilotReady', {});
	};

	return (
		<Script
			src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
			strategy="lazyOnload"
			onReady={onReady}
		/>
	);
};

export default TrustpilotScript;

import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import ApiOptions from '@/config/core/ApiOptions';
import {
	getExistingAccessTokenFromCookies,
	requestNewAccessToken,
} from '@/services/Auth/AccessToken';
import { requestNewAccessTokenFromRefresh } from '@/services/Auth/RefreshToken';
import { captureApiException } from '@/services/Utils/Sentry';

type AxiosBaseQueryArgs = { baseUrl: string | undefined };

type AxiosBaseQueryFn = BaseQueryFn<
	{
		url: string;
		method: AxiosRequestConfig['method'];
		data?: AxiosRequestConfig['data'];
		params?: AxiosRequestConfig['params'];
		headers?: AxiosRequestConfig['headers'];
	},
	unknown,
	unknown
>;

export const axiosBaseQuery =
	({ baseUrl }: AxiosBaseQueryArgs = { baseUrl: '' }): AxiosBaseQueryFn =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await axios({ baseURL: baseUrl, data, headers, method, params, url });
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			captureApiException(err, 'Generic');
			return {
				error: { data: err.response?.data, status: err.response?.status },
			};
		}
	};

const client = axios.create(ApiOptions.defaultOptions);

client.interceptors.request.use(async (config) => {
	let accessToken = getExistingAccessTokenFromCookies();

	if (accessToken) {
		config.headers.Authorization = `JWT ${accessToken}`;
	} else {
		accessToken = (await requestNewAccessToken({ password: '', username: '' })) || null;
	}

	config.headers.Authorization = `JWT ${accessToken}`;

	return config;
});

export const axiosProtectedQuery =
	({ baseUrl }: AxiosBaseQueryArgs = { baseUrl: '' }): AxiosBaseQueryFn =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await client({
				baseURL: baseUrl || process.env.NEXT_PUBLIC_BASE_URL,
				data,
				headers,
				method,
				params,
				url,
			});

			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: { data: err.response?.data, status: err.response?.status },
			};
		}
	};

const refreshAuthCallback = async (failedRequest: any) =>
	// TODO remove fix async problem
	// eslint-disable-next-line no-async-promise-executor
	new Promise(async (resolve, reject) => {
		try {
			const newAccessToken = await requestNewAccessTokenFromRefresh();
			if (newAccessToken) {
				failedRequest.response.config.headers.Authorization = `JWT ${newAccessToken}`;
				return resolve(true);
			}
			return reject(new Error("Couldn't refresh auth"));
		} catch (error: any) {
			return reject(new Error("Couldn't refresh auth"));
		}
	});

// !! Important !! Do not change call order on interceptors or Refresh logic will break
createAuthRefreshInterceptor(client, refreshAuthCallback);

// !! Important !! Do not change call order on interceptors or Refresh logic will break
client.interceptors.response.use(
	async (response: AxiosResponse) => response,
	async (error: AxiosError) =>
		new Promise((resolve, reject) => {
			captureApiException(error, 'Bumper');

			reject(error);
		})
);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';

import { IIcon, Variant } from '@/components/Icon/index.d';

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'primary':
			return 'text-primary-content';
		case 'secondary':
			return 'text-secondary-content';
		case 'secondary-background':
			return 'text-secondary';
		case 'tertiary':
			return 'text-tertiary-content';
		case 'tertiary-reverse':
			return 'text-tertiary';
		case 'accent':
			return 'text-accent';
		case 'reverse':
			return 'text-reverse-content';
		case 'success':
			return 'text-success';
		case 'error':
			return 'text-error';
		case 'error-contrast':
			return 'text-error-contrast';
		case 'warning':
			return 'text-warning';
		case 'tone-contrast':
			return 'text-tone-contrast';
		case 'tone-contrast-30':
			return 'text-tone-contrast-30';
		default:
			return 'text-primary-content';
	}
};

const Icon: FC<IIcon.IIconProps> = (props) => {
	const { iconName, variant = 'primary', className = '', size = '1x', ...componentProps } = props;

	return iconName ? (
		<FontAwesomeIcon
			icon={iconName as any}
			className={`${getColor(variant)} ${className}`}
			{...componentProps}
			size={size}
		/>
	) : (
		<></>
	);
};

export default Icon;

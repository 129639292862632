import { FC } from 'react';

// #region Global Imports
// #endregion Global Imports
// #region Local Imports
import { IContainer, Variant, Width } from '@/components/Container/index.d';
// #endregion Local Imports

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'primary':
			return 'bg-primary';
		case 'secondary-contrast':
			return 'bg-secondary-contrast';
		case 'secondary':
			return 'bg-secondary';
		case 'tertiary':
			return 'bg-tertiary';
		case 'transparent':
			return 'bg-transparent';
		case 'tertiary-contrast':
			return 'bg-tertiary-contrast';
		case 'accent':
			return 'bg-accent';
		case 'reverse':
			return 'bg-reverse';
		case 'tone-contrast':
			return 'bg-tone-contrast-5';
		case 'success':
			return 'bg-success';
		case 'primary-content':
			return 'bg-primary-content';
		case 'primary-content-transparent':
			return 'bg-primary-content opacity-80';
		default:
			return 'bg-reverse';
	}
};

export const getWidth = (width: Width) => {
	switch (width) {
		case 'centered':
			return 'max-w-3xl';
		case 'default':
			return 'xl:max-w-7xl lg:max-w-5xl';
		case 'full':
			return 'max-w-full';
		case 'medium':
			return 'max-w-5xl';
		default:
			return '';
	}
};

export const getPadding = (padding: string) => {
	switch (padding) {
		case 'sticky':
			return 'px-0 lg:px-0.5';
		case 'noPadding':
			return 'p-0';
		default:
			return 'px-1 lg:px-0.5';
	}
};

const Container: FC<IContainer.IProps> = ({
	variant = 'primary',
	width = 'default',
	children,
	className,
	customPadding = 'px-1',
	wrapperClassName = '',
}) => (
	<div data-cy="mainContainer" className={`${getColor(variant)} ${wrapperClassName}`}>
		<div
			data-cy="containerItem"
			className={`${getPadding(customPadding)} container mx-auto ${getWidth(width)} ${className}`}
		>
			{children}
		</div>
	</div>
);

export default Container;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CountryInitialState as initialState } from './initial';

export const Country = createSlice({
	initialState,
	name: 'country',
	reducers: {
		changeCountry: (state, action: PayloadAction<string>) => {
			state.country = action.payload;
		},
		setBannerClosed: (state, action: PayloadAction<boolean>) => {
			state.bannerClosed = action.payload;
		},
		setShowBanner: (state, action: PayloadAction<boolean>) => {
			state.showBanner = action.payload;
		},
	},
});

export const countryReducerName = Country.name;
export const countryReducer = Country.reducer;
export const { changeCountry, setBannerClosed, setShowBanner } = Country.actions;

export default Country;

import { GET_MY_COUNTRY } from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';

import { ICountryApi } from './index.d';

export const CountryApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		getMyCountry: builder.query<ICountryApi.IGetCountryRequest, any>({
			query: () => ({
				method: 'GET',
				params: {},
				url: GET_MY_COUNTRY,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useLazyGetMyCountryQuery } = CountryApi;

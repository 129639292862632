import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import Navbar from '@/components/Navbar';
import Ribbon from '@/components/Ribbon';
import { IDynamicLayout } from '@/layouts/DynamicLayout/index.d';
import Footer from '@/modules/Footer';
import useBrandTheme from '@/services/Hooks/useBrandTheme';
import useBrowserMounted from '@/services/Hooks/useBrowserMounted';
import { useUser } from '@/services/Hooks/useUser';

const DynamicLayout = ({
	children,
	hideNavbar,
	hideNavbarOnWhiteLabel,
	isDefaultTheme = false,
	hideMenus,
	hideAuthLinks,
	hideFooter,
}: IDynamicLayout.Props) => {
	const { userDetails } = useUser();
	const router = useRouter();
	const isBrowserMounted = useBrowserMounted();
	const { type } = useBrandTheme();
	const shouldHideNavbar = (hideNavbarOnWhiteLabel && type === 'whitelabel') || hideNavbar;

	const changeCountryFunc = (prefCountry: string) => {
		router.push('', '', {
			locale: prefCountry.toLowerCase(),
		});
	};

	useEffect(() => {
		if (userDetails?.country && userDetails?.country.toLowerCase() !== router?.locale) {
			const userLocale = userDetails?.country.toLowerCase();
			changeCountryFunc(userLocale);
		}
	}, [userDetails]);

	return (
		<>
			<Script
				type="text/javascript"
				src="https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js"
				strategy="afterInteractive"
			/>
			<Script
				type="text/javascript"
				src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"
				strategy="afterInteractive"
			/>
			<div className="font-text">
				{shouldHideNavbar ? null : (
					<Navbar
						isDefaultTheme={isDefaultTheme}
						hideMenus={hideMenus}
						hideAuthLinks={hideAuthLinks}
						disableCleanLogoRedirect={hideMenus}
					/>
				)}
				{isBrowserMounted ? <main>{children}</main> : null}
				{!hideFooter && <Footer />}
			</div>
			<Ribbon />
		</>
	);
};

export default DynamicLayout;

import { IApplyApplicationStore } from '@/redux/StateSlices/Apply/index.d';

export const ApplicationApplyState: IApplyApplicationStore.IState = {
	applyRequest: {
		addresses: [
			{
				buildingname: '',
				buildingnumber: '',
				country: 'GB',
				county: '',
				postcode: '',
				street: '',
				subbuildingname: '',
				town: '',
			},
		],
		affiliate: false,
		affiliate_src: '',
		affordability: false,
		amount: '',
		application_type: 0,
		brand: '',
		consent: false,
		creditconsent: false,
		dealership: 0,
		dob: '',
		email: '',
		forename: '',
		marketing: false,
		marketing_options: {
			mail: false,
			post: false,
			sms: false,
		},
		mobile: '',
		postcode: '',
		preapproved: false,
		promocode: '',
		registration: '',
		repaymentdate: '',
		supplier: 0,
		surname: '',
		utm_source: '',
		verificationemail: '',
	},
	applyResult: null,
	code: '',
	dealershipDetail: null,
	installments: null,
	limit: 0,
	vehicle: null,
};

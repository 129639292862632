import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import IButton from '@/components/Button/index.d';
import Icon from '@/components/Icon';
import { Body, Caption, Footnote, LargeButton } from '@/components/Text';

const getWidth = (width: IButton.Width) => {
	switch (width) {
		case 'content': {
			return {
				className: 'w-auto',
			};
		}
		case 'full': {
			return {
				className: 'w-full',
			};
		}
		default:
			return {
				className: 'w-auto',
			};
	}
};

const getSize = (size: IButton.Size) => {
	switch (size) {
		case 'xx-small': {
			return {
				className: 'py-0.5 px-1.5',
				textComponent: Caption,
			};
		}
		case 'x-small': {
			return {
				className: 'px-1.5 py-1',
				textComponent: Body,
			};
		}
		case 'small': {
			return {
				className: 'py-0.5 px-1',
				textComponent: Footnote,
			};
		}
		case 'medium': {
			return {
				className: 'py-0.5 px-1',
				textComponent: Body,
			};
		}
		case 'medium-bold': {
			return {
				className: 'py-0.5 px-1 font-bold',
				textComponent: Body,
				weight: 'bold',
			};
		}
		case 'medium-large': {
			return {
				className: 'pl-0.75 pr-0.75',
				textComponent: Body,
			};
		}
		case 'large': {
			return {
				className: 'py-0.5 px-1.5',
				textComponent: LargeButton,
			};
		}
		case 'x-large': {
			return {
				className: 'py-0.75 px-1.5',
				textComponent: LargeButton,
			};
		}
		default:
			return {
				className: 'py-0.5 px-1',
				textComponent: Body,
			};
	}
};

const getVariant = (variant: IButton.Variant) => {
	switch (variant) {
		case 'primary': {
			return {
				background: 'bg-primary border-tone-contrast hover:bg-tertiary',
				text: 'tone-contrast font-bold',
			};
		}
		case 'reverse': {
			return {
				background: 'bg-reverse border-tone-contrast hover:bg-tertiary',
				text: 'tone-contrast',
			};
		}
		case 'tone-contrast': {
			return {
				background: 'bg-tone-contrast border-tone-contrast',
				text: 'text-reverse',
			};
		}
		case 'tone-contrast-disabled': {
			return {
				background:
					'bg-tone-contrast-5 border-tone-contrast-30 hover:bg-tone-contrast-5 pointer-events-none cursor-not-allowed',
				text: 'text-tone-contrast-30',
			};
		}
		case 'tertiary': {
			return {
				background: 'bg-tertiary border-tertiary-content hover:bg-tertiary-contrast',
				text: 'tone-contrast',
			};
		}
		case 'transparent': {
			return {
				background: 'bg-transparent hover:bg-transparent-contrast',
				text: 'tone-contrast',
			};
		}
		case 'primary-content': {
			return {
				background: 'bg-transparent hover:bg-transparent-contrast',
				text: 'primary-content',
			};
		}
		case 'reverse-content': {
			return {
				background: 'bg-primary-content',
				text: 'text-reverse',
			};
		}
		case 'secondary-content': {
			return {
				background: 'bg-transparent hover:bg-transparent-contrast',
				text: 'text-reverse',
			};
		}
		case 'accent': {
			return {
				background: 'bg-accent border-tone-contrast',
				text: 'group-hover:!text-reverse !text-accent-content ',
			};
		}
		case 'none': {
			return {
				background: 'bg-transparent border-0',
				text: '',
			};
		}
		default:
			return {
				background: 'bg-primary border-tone-contrast hover:bg-tertiary',
				text: 'tone-contrast font-bold',
			};
	}
};

const getBorder = (border: boolean) => {
	if (border) {
		return 'border';
	}
	return '';
};

const getBadge = (badge: any) => {
	if (!badge && typeof badge !== 'number') return null;
	return (
		<span data-cy="badgeContainer" className="bg-reverse-content rounded-sm ml-0.25">
			<Footnote variant="secondary" className="px-0.25">
				{badge}
			</Footnote>
		</span>
	);
};

const getStyle = (structure: IButton.Structure) => {
	switch (structure) {
		case 'small':
			return 'rounded-0.25';
		case 'no-structure':
			return '';
		default:
			return 'rounded-full';
	}
};

const IconComponent: FC<IButton.IIconProps> = ({
	customIcon,
	icon,
	variant,
	position,
	className = '',
}) => {
	if (customIcon) {
		return (
			<span
				className={`${position === 'right' ? 'ml-0.5' : 'mr-0.5'} ${
					getVariant(variant).text
				} ${className}`}
			>
				{customIcon}
			</span>
		);
	}
	if (icon) {
		return (
			<Icon
				iconName={icon}
				className={`${position === 'right' ? 'ml-0.5' : 'mr-0.5'} ${
					getVariant(variant).text
				} ${className}`}
			/>
		);
	}
	return null;
};

const Button: FC<IButton.IProps> = ({
	size = 'large',
	label,
	icon,
	variant = 'accent',
	type,
	width = 'full',
	showLoader = false,
	disabled = false,
	iconComponent,
	iconComponentClassName = '',
	iconPosition = 'right',
	showLoaderWithText = false,
	border = true,
	badge,
	textClassName = '',
	showTooltip = false,
	tooltipText = '',
	textPosition = 'justify-center',
	structure = 'default',
	...props
}) => {
	const TextComponent = getSize(size).textComponent;

	return (
		<>
			{showTooltip ? (
				<ReactTooltip
					backgroundColor="var(--tertiary)"
					arrowColor="var(--tertiary)"
					effect="solid"
					id={label}
					type="dark"
					textColor="#000000"
				>
					{tooltipText}
				</ReactTooltip>
			) : null}
			<button
				{...props}
				data-tip
				data-for={label}
				className={`group ${getBorder(border)}  ${getStyle(
					structure
				)} disabled:pointer-events-none disabled:cursor-not-allowed pointer-events-auto cursor-pointer ${
					getSize(size).className
				} ${getVariant(variant).background} ${getWidth(width).className}`}
				disabled={disabled}
				// eslint-disable-next-line react/button-has-type
				type={type}
			>
				{showLoader ? (
					<TextComponent className={`${getVariant(variant).text} ${textClassName}`}>
						<Icon
							iconName={faSpinner}
							className={`mx-1 animate-spin ${getVariant(variant).text}`}
						/>
					</TextComponent>
				) : (
					<TextComponent
						// Added for Edit date button
						style={{
							whiteSpace: 'nowrap',
						}}
						className={`${getVariant(variant).text}  flex ${textPosition} items-center ${
							iconPosition === 'right' ? 'flex-row' : 'flex-row-reverse'
						} ${textClassName}`}
					>
						{label}
						{getBadge(badge)}
						<IconComponent
							icon={showLoaderWithText ? faSpinner : icon}
							className={`${
								showLoaderWithText
									? `mx-1 animate-spin ${iconComponentClassName}`
									: `${iconComponentClassName}`
							}`}
							variant={variant}
							customIcon={iconComponent}
							position={iconPosition}
						/>
					</TextComponent>
				)}
			</button>
		</>
	);
};
export default Button;

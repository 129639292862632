import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ChangeCountryBanner from '@/components/ChangeCountryBanner';
import NavbarTabs from '@/components/Navbar/NavbarTabs';
import { useLazyGetMyCountryQuery } from '@/redux/APISlices/CountryAPI/index';
import { setShowBanner } from '@/redux/StateSlices/Country/index';
import { RootState, useAppDispatch } from '@/redux/store';
import { useUser } from '@/services/Hooks/useUser';

import { INavWrapper } from './index.d';

const NavWrapper: FC<INavWrapper.IProps> = ({ children, hideMenus }) => {
	const [navbarClassName, setNavbarClassName] = useState('');
	const router = useRouter();
	const { isVisitor } = useUser();
	const [trigger, { data }] = useLazyGetMyCountryQuery();
	const bannerVisibleClass =
		'flex-row md:top-0 top-0 lg:pr-1 lg:mt-1 lg:top-0 mb-8 md:mb-6 lg:mb-8';
	const bannerHiddenClass = 'lg:mt-0 lg:top-0 md:top-24 top-40 flex-col lg:h-0';
	const showBanner = useSelector((state: RootState) => state.country.showBanner);
	const bannerClosed = useSelector((state: RootState) => state.country.bannerClosed);
	const dispatch = useAppDispatch();

	// This is to get data from useLazyGetMyCountryQuery. We have to send an empthy object inside the trigger function.
	useEffect(() => {
		trigger({});
	}, []);

	// This useEffect checks if the user's current location (countryCode) is different from the router locale and if the banner is not closed by user.
	// If the condition is met, it sets the showBanner state to true and updates the banner visibility accordingly.
	// Otherwise, it sets the showBanner state to false and updates the banner visibility to be hidden.
	useEffect(() => {
		const countryCode = data?.country_code.toLocaleLowerCase();

		const isCountryAndLocaleNotMatching = countryCode !== router.locale;

		if (!data) {
			return;
		}

		if (isCountryAndLocaleNotMatching && !bannerClosed && isVisitor) {
			dispatch(setShowBanner(true));
			setNavbarClassName(bannerVisibleClass);
		} else {
			dispatch(setShowBanner(false));
			setNavbarClassName(bannerHiddenClass);
		}
	}, [data, bannerClosed, router, isVisitor, dispatch]);

	return (
		<nav className={`top-0 w-full z-40 universal mb-3 ${navbarClassName}`}>
			<div className="fixed flex flex-col justify-between items-center top-0 z-50 w-full">
				{data && showBanner && isVisitor ? <ChangeCountryBanner /> : null}
				{!hideMenus && <NavbarTabs />}
				<div className="bg-primary lg:border-b border-black w-full lg:rounded-b-1">{children}</div>
			</div>
		</nav>
	);
};

export default NavWrapper;

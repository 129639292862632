import {
	faFacebookF,
	faInstagram,
	faLinkedinIn,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import type { FunctionComponent } from 'react';

import Icon from '@/components/Icon';
import Index from '@/components/Image';
import { LinkExternal } from '@/components/Link';
import { Body } from '@/components/Text';

export const links = [
	{
		external: false,
		name: 'howitworks',
		path: '/howitworks',
	},
	{
		external: false,
		name: 'about',
		path: '/about',
	},
	{
		external: false,
		name: 'blog',
		path: '/blog',
	},
	{
		blacklist: ['de', 'es', 'pt', 'nl'],
		// icon: LEAF,
		external: false,
		name: 'sustainability',
		path: '/sustainability',
	},
	{
		external: true,
		name: 'career',
		path: 'https://bumper-1692709783.teamtailor.com/',
	},
];

const FooterLinks: FunctionComponent = () => {
	const { t } = useTranslation('footer');

	const socialIcons = [
		{
			icon: faTwitter,
			url: 'https://twitter.com/bumperpay',
		},
		{
			icon: faLinkedinIn,
			url: t('socialMediaLinks.linkedin'),
		},
		{
			icon: faFacebookF,
			url: 'https://www.facebook.com/Bumperpay/',
		},
		{
			icon: faInstagram,
			url: 'https://www.instagram.com/bumperpay/',
		},
	];

	return (
		<div className="pr-0 md:pr-2 lg:pr-6">
			<Index
				src="images/logos/bumper-main.svg"
				height={40}
				width={153}
				className="bumper-logo"
				alt="bumper-logo"
			/>
			<div className="mt-md">
				<div className="mt-1">
					<Body className="text-xl mb-0.5 font-bold border-b inline-block" weight="bold">
						<span className="mr-1">
							<Icon iconName={faPhone} variant="primary" />
						</span>
						<LinkExternal
							href={`tel:${t('common:bumper.number')}`}
							className="hover:no-underline"
							variant="primary"
						>
							{t('common:bumper.number')}
						</LinkExternal>
					</Body>
				</div>

				<div>
					<Body className="text-xl mb-1 font-bold border-b inline-block" weight="bold">
						<span className="mr-1">
							<Icon iconName={faEnvelope} variant="primary" />
						</span>
						<LinkExternal
							href={`mailto:${t('common:bumper.support')}`}
							className="hover:no-underline"
							variant="primary"
						>
							{t('common:bumper.support')}
						</LinkExternal>
					</Body>
				</div>
			</div>
			<div className="mt-0.5 flex items-center">
				<Body className="mr-1">{t('followUs')}:</Body>
				{socialIcons.map((icon) => (
					<div
						className="mr-1 border rounded-xs w-8 h-8 flex items-center justify-center"
						key={icon.url}
					>
						<a href={icon.url} target="_blank" rel="noreferrer" className="leading-none">
							<Icon iconName={icon.icon} variant="primary" className="text-n3 cursor-pointer w-4" />
						</a>
					</div>
				))}
			</div>
		</div>
	);
};
export default FooterLinks;

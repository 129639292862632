import { useRouter } from 'next/router';
import { FC, useContext } from 'react';

import { useTypedSelector } from '@/redux/store';
import useIsomorphicLayoutEffect from '@/services/Hooks/useIsomorphicLayoutEffect';
import useThemeIntegration from '@/services/Hooks/useThemeIntegration';
import { useUser } from '@/services/Hooks/useUser';
import { FALLBACK_THEME } from '@/services/Providers/Theme';
import { StatsigParameterContext } from '@/services/Utils/Statsig/statsigManager/StatsigParameterContext';

const ThemeProvider: FC = ({ children }) => {
	const { userDetails } = useUser();
	const isDealershipRamstein = userDetails?.is_ramstein_active;
	const { changeTheme, themeName } = useThemeIntegration();
	const statsigParameters = useContext(StatsigParameterContext);
	const themeStore = useTypedSelector((state) => state.theme);
	const {
		query: { supplier, theme },
	} = useRouter();

	useIsomorphicLayoutEffect(() => {
		if (themeStore) {
			if (isDealershipRamstein) {
				changeTheme('ramstein');
			} else if (supplier || theme) {
				changeTheme((theme as string) || (supplier as string));
			} else if (
				statsigParameters?.theme_name &&
				themeStore?.name?.includes('bumper') &&
				themeStore?.name !== statsigParameters?.theme_name
			) {
				changeTheme(statsigParameters.theme_name);
			} else {
				changeTheme(themeName || FALLBACK_THEME);
			}
		}
	}, [isDealershipRamstein, supplier, theme, themeStore]);

	return <>{children}</>;
};

export default ThemeProvider;

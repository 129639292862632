import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationInitialState as initialState } from '@/redux/StateSlices/Application/initial';

import { IApplicationStore } from './index.d';

export const Application = createSlice({
	initialState,
	name: 'applicationState',
	reducers: {
		init: (state, action: PayloadAction<IApplicationStore.IState>) => ({
			...action.payload,
			initialized: true,
		}),
		setSelectedCustomerType: (state, action: PayloadAction<any>) => {
			state.selectedCustomerType = action.payload;
		},

		setSelectedPaymentType: (state, action: PayloadAction<any>) => {
			state.selectedPaymentType = action.payload;
		},
		updateAmount: (state, action: PayloadAction<any>) => {
			state.amount = action.payload.amount;
		},
		updateApplicationState: (
			state,
			action: PayloadAction<IApplicationStore.IUpdateApplicationActionPayload>
		) => {
			state.flagdate = action.payload.flagdate;
			state.amount = action.payload.amount;
			state.repaymentdate = action.payload.repaymentdate;
			state.preapproved_wired = action.payload.preapproved_wired;
			state.instalments = action.payload.instalments;
			state.deposit = action.payload.deposit;
			state.deposit_amount = action.payload.deposit_amount;
		},
		updateFlagDate: (state, action: PayloadAction<any>) => {
			state.flagdate = action.payload.flagdate;
		},
		updateFormAmount: (state, action: PayloadAction<any>) => {
			state.formAmount = action.payload.formAmount;
		},
		updateInstalments: (state, action: PayloadAction<any>) => {
			state.instalments = action.payload.instalments;
		},
		updateProductId: (state, action: PayloadAction<any>) => {
			state.product_id = action.payload.product_id;
		},
	},
});

export const applicationReducerName = Application.name;
export const applicationReducer = Application.reducer;
export const {
	init,
	updateApplicationState,
	updateFlagDate,
	updateAmount,
	updateInstalments,
	updateFormAmount,
	setSelectedPaymentType,
	setSelectedCustomerType,
	updateProductId,
} = Application.actions;

export default Application.reducer;

import { useTranslation } from 'next-i18next';

import { selectTheme } from '@/redux/StateSlices/Theme';
import { useTypedSelector } from '@/redux/store';
import { FALLBACK_THEME } from '@/services/Providers/Theme';

const useThemeAwareTranslation = (ns: string, options?: any) => {
	const { t, i18n } = useTranslation(ns, options);
	const theme = useTypedSelector(selectTheme);

	const getThemedKey = (key: string, params?: any) => {
		let keyNS = ns;
		const keyArray = key.split(':');
		// eslint-disable-next-line prefer-destructuring
		if (keyArray.length > 1) keyNS = keyArray[0];

		const themedKey = `${keyNS}:${keyArray.slice(-1)[0].replace(/#theme#/gi, theme)}`;
		if (i18n?.exists?.(themedKey)) return t(themedKey, params);
		const fallbackKey = `${keyNS}:${keyArray.slice(-1)[0].replace(/#theme#/gi, FALLBACK_THEME)}`;
		if (i18n?.exists?.(fallbackKey)) return t(fallbackKey, params);
		return '';
	};

	return { t: getThemedKey };
};

export default useThemeAwareTranslation;

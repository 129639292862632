import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserAPI } from '@/redux/APISlices/UserAPI/index.d';
import { UserInitialState as initialState } from '@/redux/StateSlices/User/initial';

export const User = createSlice({
	initialState,
	name: 'userState',
	reducers: {
		clearUserDetailState: (state) => {
			state.userDetails = null;
			state.user = null;
		},
		setIsNotificationsRead: (state, action: PayloadAction<boolean>) => {
			state.isNotificationsRead = action.payload;
		},
		setNotifications: (state, action: PayloadAction<any>) => {
			state.notifications = action.payload;
		},
		setUserDetailState: (state, action: PayloadAction<IUserAPI.IGetApiLastLoginResponse>) => {
			state.userDetails = action.payload;
		},
	},
});

export const userReducerName = User.name;
export const userReducer = User.reducer;
export const {
	setUserDetailState,
	clearUserDetailState,
	setIsNotificationsRead,
	setNotifications,
} = User.actions;
export const notificationSelector = (state: any) => state[User.name].isNotificationsRead;

export default User.reducer;

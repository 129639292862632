import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosProtectedQuery } from '@/config/core';

const BumperApiWithoutTags = createApi({
	baseQuery: axiosProtectedQuery({
		baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
	}),
	endpoints: () => ({}),
	reducerPath: 'BumperApi',
});

export const BumperApi = BumperApiWithoutTags.enhanceEndpoints({
	addTagTypes: ['ApplicationData', 'DealershipData'],
});

export const BumperApiReducerName = BumperApi.reducerPath;
export const BumperApiReducer = BumperApi.reducer;
export const BumperApiMiddleware = BumperApi.middleware;

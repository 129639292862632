import { GET_NOTIFICATIONS } from '@/redux/APIs/ApiList';
import { BumperApi } from '@/redux/APIs/BumperApi';

export const NotificationApi = BumperApi.injectEndpoints({
	endpoints: (builder) => ({
		getNotifications: builder.query<any, void>({
			query: () => ({
				method: 'GET',
				params: {},
				url: GET_NOTIFICATIONS,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery } = NotificationApi;

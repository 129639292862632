// @ts-ignore
import findLast from 'array.prototype.findlast';
import { useEffect, useRef } from 'react';

import { setEventStore } from '@/redux/StateSlices/Analytics';
import { useAppDispatch, useTypedSelector } from '@/redux/store';

type EventType = string;

const useGoogleTagManager = () => {
	const environment = process.env.NEXT_PUBLIC_ENV;
	const consentRefHasResponse = useRef<boolean>(false);
	const eventStore = useTypedSelector((state) => state.analytics.eventStore);
	const dispatch = useAppDispatch();

	const runEventBus = () => {
		eventStore.forEach((event: any) => {
			if (event) {
				window.dataLayer.push(event);
			}
		});
		dispatch(setEventStore([]));
	};

	const comparer = (e: any) => e.event === 'page view';

	useEffect(() => {
		if (typeof window !== 'undefined' && window.dataLayer) {
			consentRefHasResponse.current = window?.Cookiebot?.hasResponse || true;
		}
	});

	useEffect(() => {
		if (!window.eventBusRan && window.dataLayer && consentRefHasResponse.current) {
			if (eventStore.length > 0) {
				window.eventBusRan = true;
				runEventBus();
			}
		}
	}, [consentRefHasResponse.current]);

	// eslint-disable-next-line consistent-return
	return (event: EventType, data: Record<any, any>) => {
		const eventData = {
			environment,
			event,
			...data,
		};

		if (
			(environment === 'production' || environment === 'demo' || environment === 'stg') &&
			typeof window !== 'undefined' &&
			window.dataLayer
		) {
			if (consentRefHasResponse.current) {
				if (eventStore.length > 0) {
					runEventBus();
				}
				window.dataLayer.push(eventData);
			} else {
				const lastPageView = findLast(eventStore, comparer);
				if (lastPageView) {
					if (lastPageView?.page?.title !== data?.page?.title) {
						dispatch(setEventStore([...eventStore, eventData]));
					}
				} else {
					dispatch(setEventStore([...eventStore, eventData]));
				}
			}
		}
	};
};

export {
	// eslint-disable-next-line import/prefer-default-export
	useGoogleTagManager,
};

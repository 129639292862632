import { BaseQueryFn, createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '@/config/core';

interface ExtraOptions {
	type?: 'strapi' | 'base' | 'address' | 'companySearch' | 'manualCompanySearch';
}

const baseQuery: BaseQueryFn<any, unknown, unknown, ExtraOptions> = (args, api, extraOptions) => {
	const { type } = extraOptions || { type: 'no options' };

	let baseUrl = '/';

	if (type === 'strapi') {
		baseUrl = process.env.NEXT_PUBLIC_STRAPI_URL || 'https://blog.bumper.co';
	}

	if (type === 'address') {
		baseUrl = process.env.NEXT_PUBLIC_ADDRESS_API_URL || '';
	}

	if (type === 'companySearch') {
		baseUrl = process.env.NEXT_PUBLIC_COMPANY_SEARCH_API_URL || '';
	}

	if (type === 'companySearch') {
		baseUrl = process.env.NEXT_PUBLIC_COMPANY_SEARCH_API_URL || '';
	}

	if (type === 'manualCompanySearch') {
		baseUrl = process.env.NEXT_PUBLIC_COMPANY_MANUAL_SEARCH_API_URL || '';
	}

	return axiosBaseQuery({
		baseUrl,
	})(args, api, extraOptions);
};

export const GenericFetchApi = createApi({
	baseQuery,
	endpoints: () => ({}),
	reducerPath: 'GenericFetchApi',
});

export const GenericFetchApiReducerName = GenericFetchApi.reducerPath;
export const GenericFetchApiReducer = GenericFetchApi.reducer;
export const GenericFetchApiMiddleware = GenericFetchApi.middleware;

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { GetServerSidePropsContext } from 'next';
import nookies, { parseCookies, setCookie } from 'nookies';

import ApiOptions from '@/config/core/ApiOptions';
import { AUTH_ACCESS_TOKEN } from '@/redux/APIs/ApiList';
import { IAuthApi } from '@/redux/APISlices/AuthAPI/index.d';
import { IAuth, USER_GROUPS } from '@/services/Auth/index.d';

const initialTokenValues = {
	email: 'visitor@visitor.com',
	exp: 0,
	orig_iat: 0,
	reset_password: false,
	user_groups: USER_GROUPS.VISITOR,
	user_id: 0,
	username: 'visitor',
};

const getExistingAccessTokenFromCookies = (ctx?: any): string | null => {
	// Client site get cookie
	if (typeof window !== 'undefined') {
		/// Parse
		const cookies = parseCookies();

		// eslint-disable-next-line dot-notation
		return cookies?.['_accessToken'];
	}

	if (ctx) {
		// Server side get cookie
		const cookies = nookies.get(ctx);

		// eslint-disable-next-line dot-notation
		return cookies?.['_accessToken'];
	}

	return null;
};

const returnDecodedAccessTokenValues = (accessToken?: string, ctx?: GetServerSidePropsContext) => {
	const token = accessToken || getExistingAccessTokenFromCookies(ctx);

	const decodedAccessToken: IAuth.AccessToken = token
		? jwtDecode(token as string)
		: initialTokenValues;

	const userGroupOfUserIsKnown = Boolean(
		Object.values(USER_GROUPS).filter((item) => item === decodedAccessToken?.user_groups)[0]
	);

	return {
		cookieMaxAge: decodedAccessToken?.exp,
		isPasswordOfUserExpired: !!decodedAccessToken?.reset_password,
		isStaff: decodedAccessToken?.user_groups === USER_GROUPS.STAFF,
		isVisitor: decodedAccessToken?.user_groups === USER_GROUPS.VISITOR,
		userGroup: decodedAccessToken?.user_groups,
		userGroupOfUserIsKnown,
		userHasNoUserGroup: !decodedAccessToken?.user_groups,
		username: decodedAccessToken?.username,
	};
};

const setAccessTokenToCookie = (token: string) => {
	const { cookieMaxAge } = returnDecodedAccessTokenValues(token);

	// Client site cookie
	if (typeof window !== 'undefined') {
		// set cookie
		setCookie(null, '_accessToken', token, {
			maxAge: cookieMaxAge,
			path: '/',
		});
	}
	// else {
	// 	// set Serverside cookie
	// 	nookies.set(null, '_accessToken', token, {
	// 		maxAge: cookieMaxAge,
	// 		path: '/',
	// 	});
	// }
};

const requestNewAccessToken = async (credentials: IAuthApi.IRequest): Promise<string | false> => {
	const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL;
	const client = axios.create(ApiOptions.defaultOptions);
	try {
		const response: any = await client.post(`${getBaseUrl()}${AUTH_ACCESS_TOKEN}`, {
			...credentials,
		});
		if (response?.data?.jwt_token) {
			setAccessTokenToCookie(response?.data?.jwt_token);
			return response?.data?.jwt_token;
		}
		return false;
	} catch (error) {
		return false;
	}
};

export {
	getExistingAccessTokenFromCookies,
	requestNewAccessToken,
	returnDecodedAccessTokenValues,
	setAccessTokenToCookie,
};

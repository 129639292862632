import { IUserStore } from '@/redux/StateSlices/User/index.d';

export const UserInitialState: IUserStore.IState = {
	isNotificationsRead: false,
	isUserDetailsChanged: false,
	notifications: null,
	user: null,
	userDetails: null,
	userType: 'visitor',
};

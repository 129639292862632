import NextImage from 'next/image';
import { FC } from 'react';

import { IImage } from '@/components/Image/index.d';
import useBrowserMounted from '@/services/Hooks/useBrowserMounted';

const Image: FC<IImage> = ({
	src,
	base64 = '',
	remote = true,
	variant = '',
	external = false,
	client = false,
	...props
}) => {
	const mounted = useBrowserMounted();
	const getImageSource = () => {
		if (base64) {
			return base64;
		}
		if (remote) {
			return `${process.env.NEXT_PUBLIC_S3_URL}${src}`;
		}
		return `/assets/${src}`;
	};

	const getBlogLoader = (args: any) => `${args.src}?w=${args.width}&q=${args.quality || 75}`;

	if (client) {
		if (!mounted) {
			return null;
		}
	}

	if (external) {
		return (
			// @ts-ignore
			// eslint-disable-next-line @next/next/no-img-element
			<img src={src} {...props} alt="img" />
		);
	}
	if (variant && variant === 'blog') {
		return (
			<>
				<NextImage src={src} loader={getBlogLoader} {...props} />
			</>
		);
	}
	return (
		<>
			<NextImage src={getImageSource()} {...props} />
		</>
	);
};

export default Image;

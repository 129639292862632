export const STATSIG_USER_COOKIE = 'test_user_cookie';
// This is the experiment that will be used to determine the parameter
export const STATSIG_EXPERIMENT_PARAMETER = 'site_type';
// This is the experiment parameter that will be used to determine the parameter
export const EXPERIMENT = 'test_homepage';
// Default Experiment Group Fallback
export const GROUP_PARAM_FALLBACK = 'error_default_bucket';

export enum STATSIG_EXPERIMENTS {
	INTEGRATION_PAYNOW_DESCRIPTION = 'integration_paynow_description',
	INTEGRATION_PAYLATER_DESCRIPTION = 'integration_paylater_description',
	DEFAULT_THEME = 'default_theme',
	TEST_HOMEPAGE = 'test_homepage',
}

export enum STATSIG_PARAMETERS {
	SITE_TYPE = 'site_type',
	PAYNOW_DESCRIPTION = 'paynow_description',
	PAYLATER_DESCRIPTION = 'paylater_description',
	THEME_NAME = 'theme_name',
}

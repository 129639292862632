// In order to use this file, you must have the following
// data-theme configurations in your project:
const THEMES = [
	'bumper',
	'arnoldclark',
	'arnoldclarkauto',
	'ats',
	'audi',
	'ford',
	'harley',
	'jlr',
	'kwikfit',
	'seat',
	'servicesure',
	'skoda',
	'volkswagen',
	'vchrgd',
	'tyrepros',
	'checkstar',
	'evanshalshaw',
	'ramstein',
	'stratstone',
	'carstore',
	'toyotafs',
	'stapletons',
	'infomedia',
	'ramp',
	'suzuki',
	'hyundai',
	'waylands',
	'bumper_n1',
	'bumper_n2',
] as const;

export const FALLBACK_THEME = 'bumper';

export type THEME = (typeof THEMES)[number];

export interface ITheme {
	loginBackground?: string;
	logo: { src: string; height: number; width: number };
	// name ==> css style name
	name: THEME;
	readableName: string;
	marketing: boolean;
	favicon?: any;
	type: 'whitelabel' | 'co-branding' | 'default';
	secondaryLogo?: { src: string; height: number; width: number };
}

export interface IConfiguration {
	// key ==> supplier name
	[key: string]: ITheme;
}

export const getFavIconProperties = (themeKey = FALLBACK_THEME) => [
	{
		href: `https://bumper-live-public.s3.eu-west-1.amazonaws.com/images/favicon/${themeKey}/favicon.ico`,
		rel: 'shortcut icon',
	},
	{
		href: `https://bumper-live-public.s3.eu-west-1.amazonaws.com/images/favicon/${themeKey}/favicon-32x32.png`,
		rel: 'icon',
		sizes: '32x32',
		type: 'image/png',
	},
	{
		href: `https://bumper-live-public.s3.eu-west-1.amazonaws.com/images/favicon/${themeKey}/favicon-16x16.png`,
		rel: 'icon',
		sizes: '16x16',
		type: 'image/png',
	},
	{
		href: `https://bumper-live-public.s3.eu-west-1.amazonaws.com/images/favicon/${themeKey}/apple-touch-icon.png`,
		rel: 'apple-touch-icon',
		sizes: '180x180',
	},
	{
		href: `https://bumper-live-public.s3.eu-west-1.amazonaws.com/images/favicon/${themeKey}/site.webmanifest`,
		rel: 'manifest',
	},
];

export const themeConfigurations: IConfiguration = {
	arnoldclark: {
		favicon: getFavIconProperties('arnoldclark'),
		loginBackground: 'images/banners/arnoldclark-bg.jpg',
		logo: { height: 30, src: 'images/logos/easy-pay.svg', width: 148 },
		marketing: false,
		name: 'arnoldclark',
		readableName: 'EasyPay',
		secondaryLogo: { height: 32, src: 'images/logos/easyPayLogo.svg', width: 160 },
		type: 'whitelabel',
	},
	arnoldclarkautoparts: {
		favicon: getFavIconProperties('arnoldclark'),
		logo: { height: 30, src: 'images/logos/ac-auto.png', width: 148 },
		marketing: false,
		name: 'arnoldclarkauto',
		readableName: 'EasyPay',
		secondaryLogo: { height: 32, src: 'images/logos/easyPayLogo.svg', width: 160 },
		type: 'whitelabel',
	},
	ats: {
		favicon: getFavIconProperties(),
		logo: { height: 36, src: 'images/logos/ats.png', width: 90 },
		marketing: false,
		name: 'ats',
		readableName: 'ATS Euromaster',
		type: 'co-branding',
	},
	audi: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/audi-bg.jpg',
		logo: { height: 34, src: 'images/logos/audi.svg', width: 82 },
		marketing: false,
		name: 'audi',
		readableName: 'Audi',
		secondaryLogo: { height: 34, src: 'images/logos/audi-white.svg', width: 82 },
		type: 'co-branding',
	},
	audicam: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/audi-bg.jpg',
		logo: { height: 34, src: 'images/logos/audi.svg', width: 82 },
		marketing: false,
		name: 'audi',
		readableName: 'Audicam',
		secondaryLogo: { height: 34, src: 'images/logos/audi-white.svg', width: 82 },
		type: 'co-branding',
	},
	bumper: {
		favicon: getFavIconProperties(),
		logo: { height: 34, src: 'images/logos/bumper-main.svg', width: 130 },
		marketing: true,
		name: 'bumper',
		readableName: 'Bumper',
		secondaryLogo: { height: 32, src: 'images/logos/bumper-orange.svg', width: 126 },
		type: 'default',
	},
	carstore: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/carstore-bg.jpg',
		logo: {
			height: 40,
			src: 'images/logos/car-store.svg',
			width: 123,
		},
		marketing: false,
		name: 'carstore',
		readableName: 'Easypay',
		type: 'co-branding',
	},
	checkstar: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/marelli-bg.png',
		logo: { height: 51, src: 'images/logos/marelli-logo.svg', width: 139 },
		marketing: false,
		name: 'checkstar',
		readableName: 'CheckStar',
		secondaryLogo: { height: 70, src: 'images/logos/marelli-reverse-logo.svg', width: 250 },
		type: 'co-branding',
	},
	evanshalshaw: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/evanshalshaw-bg.jpg',
		logo: {
			height: 40,
			src: 'images/logos/evans-halshaw.svg',
			width: 123,
		},
		marketing: false,
		name: 'evanshalshaw',
		readableName: 'Easypay',
		type: 'co-branding',
	},
	bumper_n1: {
		favicon: getFavIconProperties(),
		logo: { height: 34, src: 'images/logos/bumper-main.svg', width: 130 },
		marketing: false,
		name: 'bumper_n1',
		readableName: 'Bumper',
		type: 'co-branding',
	},
	ford: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/ford-bg.png',
		logo: { height: 45, src: 'images/logos/ford-nav.png', width: 120 },
		marketing: false,
		name: 'ford',
		readableName: 'Ford EasyPay',
		type: 'co-branding',
	},
	bumper_n2: {
		favicon: getFavIconProperties(),
		logo: { height: 34, src: 'images/logos/bumper-main.svg', width: 130 },
		marketing: false,
		name: 'bumper_n2',
		readableName: 'Bumper',
		type: 'co-branding',
	},
	harleydavidson: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/harley-bg.png',
		logo: { height: 24, src: 'images/logos/hd-credit.svg', width: 172 },
		marketing: false,
		name: 'harley',
		readableName: 'Harley Credit',
		type: 'co-branding',
	},
	hyundai: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/hyundai-bg.png',
		logo: { height: 51, src: 'images/logos/hyundai.svg', width: 154 },
		marketing: false,
		name: 'hyundai',
		readableName: 'Hyundai',
		secondaryLogo: { height: 51, src: 'images/logos/hyundai-alt.svg', width: 154 },
		type: 'co-branding',
	},
	infomedia: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/infomedia-bg.png',
		logo: { height: 24, src: 'images/logos/infomedia.svg', width: 172 },
		marketing: false,
		name: 'infomedia',
		readableName: 'infomedia',
		secondaryLogo: { height: 24, src: 'images/logos/infomedia-white.svg', width: 172 },
		type: 'co-branding',
	},
	jlr: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/jlr-bg.png',
		logo: { height: 40, src: 'images/logos/jlr.png', width: 120 },
		marketing: false,
		name: 'jlr',
		readableName: 'FlexPay Jaguar Land Rover',
		secondaryLogo: { height: 70, src: 'images/logos/jlr_white.svg', width: 150 },
		type: 'whitelabel',
	},
	kwikfit: {
		favicon: getFavIconProperties(),
		logo: { height: 27, src: 'images/logos/kwik-fit.png', width: 139 },
		marketing: false,
		name: 'kwikfit',
		readableName: 'Kwikfit',
		type: 'co-branding',
	},
	ramp: {
		favicon: getFavIconProperties(),
		logo: { height: 70, src: 'images/logos/ramp.png', width: 96 },
		marketing: true,
		name: 'ramp',
		readableName: 'Ramp',
		secondaryLogo: { height: 70, src: 'images/logos/ramp.png', width: 96 },
		type: 'co-branding',
	},
	ramstein: {
		favicon: getFavIconProperties(),
		logo: { height: 40, src: 'images/logos/ramstein.png', width: 95 },
		marketing: false,
		name: 'ramstein',
		readableName: 'Ramstein',
		secondaryLogo: { height: 120, src: 'images/logos/ramstein_white.svg', width: 118 },
		type: 'co-branding',
	},
	seat: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/seat-bg.jpg',
		logo: { height: 51, src: 'images/logos/seat_black.svg', width: 41 },
		marketing: false,
		name: 'seat',
		readableName: 'SEAT',
		secondaryLogo: { height: 51, src: 'images/logos/seat_white.svg', width: 41 },
		type: 'co-branding',
	},
	servicesure: {
		favicon: getFavIconProperties(),
		logo: { height: 31, src: 'images/logos/servicesure.png', width: 161 },
		marketing: false,
		name: 'servicesure',
		readableName: 'Servicesure',
		type: 'co-branding',
	},
	skoda: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/skoda-bg.jpg',
		logo: { height: 48, src: 'images/logos/skoda.png', width: 40 },
		marketing: false,
		name: 'skoda',
		readableName: 'Skoda',
		type: 'co-branding',
	},
	stapletons: {
		favicon: getFavIconProperties(),
		logo: { height: 30, src: 'images/logos/stapletons.png', width: 143 },
		marketing: false,
		name: 'stapletons',
		readableName: 'Stapletons',
		secondaryLogo: { height: 30, src: 'images/logos/stapletons-light.png', width: 143 },
		type: 'co-branding',
	},
	stratstone: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/stratstone-bg.png',
		logo: {
			height: 40,
			src: 'images/logos/stratstone.svg',
			width: 123,
		},
		marketing: false,
		name: 'stratstone',
		readableName: 'Platinum Pay',
		type: 'co-branding',
	},
	suzuki: {
		favicon: getFavIconProperties(),
		logo: { height: 41, src: 'images/logos/suzuki.svg', width: 41 },
		marketing: false,
		name: 'suzuki',
		readableName: 'Suzuki',
		secondaryLogo: { height: 51, src: 'images/logos/suzuki-white.svg', width: 41 },
		type: 'co-branding',
	},
	toyotafs: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/toyota-bg.png',
		logo: { height: 36, src: 'images/logos/toyota-fs.png', width: 170 },
		marketing: false,
		name: 'toyotafs',
		readableName: 'Toyota Financial Services',
		secondaryLogo: { height: 36, src: 'images/logos/toyota-alt.png', width: 130 },
		type: 'co-branding',
	},
	tyrepros: {
		favicon: getFavIconProperties(),
		logo: { height: 36, src: 'images/logos/tyrepros-logo.png', width: 130 },
		marketing: false,
		name: 'tyrepros',
		readableName: 'Tyre Pros',
		secondaryLogo: { height: 36, src: 'images/logos/tyrepros-logo-white.svg', width: 130 },
		type: 'co-branding',
	},
	vchrgd: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/eve-bg.png',
		logo: { height: 20, src: 'images/logos/eveLogo.svg', width: 150 },
		marketing: false,
		name: 'vchrgd',
		readableName: 'VCHRGD TECHNOLOGIES LTD',
		secondaryLogo: { height: 32, src: 'images/logos/eve-secondary-logo.svg', width: 126 },
		type: 'co-branding',
	},
	volkswagen: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/vw-bg.jpg',
		logo: { height: 41, src: 'images/logos/vw.png', width: 41 },
		marketing: false,
		name: 'volkswagen',
		readableName: 'volkswagen',
		type: 'co-branding',
	},
	vwcommercial: {
		favicon: getFavIconProperties(),
		loginBackground: 'images/banners/vwcv-bg.jpg',
		logo: { height: 41, src: 'images/logos/vw.png', width: 41 },
		marketing: false,
		name: 'volkswagen',
		readableName: 'volkswagen Commercial',
		type: 'co-branding',
	},
	waylands: {
		favicon: getFavIconProperties(),
		logo: { height: 25, src: 'images/logos/waylands.svg', width: 128 },
		marketing: false,
		name: 'waylands',
		readableName: 'waylands',
		type: 'co-branding',
	},
};

export const getCorrectThemeConfiguration = (theme: string) =>
	themeConfigurations[theme] || themeConfigurations[FALLBACK_THEME];

import axios from 'axios';

import ApiOptions from '@/config/core/ApiOptions';
import { AUTH_REFRESH_TOKEN } from '@/redux/APIs/ApiList';
import {
	getExistingAccessTokenFromCookies,
	returnDecodedAccessTokenValues,
	setAccessTokenToCookie,
} from '@/services/Auth/AccessToken';
import { publish } from '@/services/Events';
import { captureExceptionAndLogToSentry } from '@/services/Utils/Sentry';

const handleErrorOnRefreshingAccessToken = (accessToken: string) => {
	const { isPasswordOfUserExpired, isStaff, username } =
		returnDecodedAccessTokenValues(accessToken);

	if (isPasswordOfUserExpired) {
		publish('PasswordIsExpired', { token: accessToken, username });
	}

	if (isStaff) {
		publish('StaffTokenExpire', { token: accessToken });
	}
};

const requestNewAccessTokenFromRefresh = async () => {
	const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL;
	const client = axios.create(ApiOptions.defaultOptions);
	const oldAccessToken = getExistingAccessTokenFromCookies();

	try {
		const response: any = await client.post(`${getBaseUrl()}${AUTH_REFRESH_TOKEN}`, {
			token: oldAccessToken,
		});

		const newAccessToken = response?.data?.token;

		if (newAccessToken) {
			const { isPasswordOfUserExpired } = returnDecodedAccessTokenValues(newAccessToken);

			if (isPasswordOfUserExpired) {
				return handleErrorOnRefreshingAccessToken(newAccessToken);
			}

			setAccessTokenToCookie(newAccessToken);
			return response?.data?.token;
		}

		if (oldAccessToken) {
			return handleErrorOnRefreshingAccessToken(oldAccessToken);
		}

		captureExceptionAndLogToSentry('No Access token on refresh token', '', '', {}, 'auth', 'error');

		return '';
	} catch (error) {
		if (oldAccessToken) {
			return handleErrorOnRefreshingAccessToken(oldAccessToken);
		}
		return '';
	}
};

export { requestNewAccessTokenFromRefresh };

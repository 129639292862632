import { useTheme } from 'next-themes';
import { useDispatch } from 'react-redux';

import { changeTheme as ChangeThemeAction } from '@/redux/StateSlices/Theme';
import { getCorrectThemeConfiguration } from '@/services/Providers/Theme';

const useThemeIntegration = () => {
	const { setTheme, theme: themeName } = useTheme();
	const dispatch = useDispatch();

	const changeTheme = (theme: string) => {
		const { name } = getCorrectThemeConfiguration(theme);
		dispatch(ChangeThemeAction(name));
		setTheme(name);
	};

	return {
		changeTheme,
		themeName,
	};
};

export default useThemeIntegration;

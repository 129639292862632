export const filterInputProps = (props: any) => {
	const filteredProps: any = { ...props };
	delete filteredProps.filterDate;
	delete filteredProps.onDateChange;
	delete filteredProps.startDate;
	delete filteredProps.minDate;
	delete filteredProps.maxDate;
	return filteredProps;
};

export const filterTextProps = (props: any) => {
	const filteredProps: any = { ...props };
	delete filteredProps.clearValue;
	delete filteredProps.getStyles;
	delete filteredProps.getValue;
	delete filteredProps.hasValue;
	delete filteredProps.isMulti;
	delete filteredProps.isRtl;
	delete filteredProps.selectOption;
	delete filteredProps.selectProps;
	delete filteredProps.setValue;
	delete filteredProps.isDisabled;
	delete filteredProps.isFocused;
	delete filteredProps.innerProps;
	delete filteredProps.cx;
	return filteredProps;
};

import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC } from 'react';

import { UNITED_KINGDOM } from '@/constants/locales';

interface IProps {
	renderCookieConsent: boolean;
}

const TrackingScripts: FC<IProps> = ({ renderCookieConsent = true }) => {
	const { locale = UNITED_KINGDOM } = useRouter();
	const permission = Boolean(JSON.parse(process.env.NEXT_PUBLIC_TRACKING_PERMISSION || 'false'));
	return (
		<>
			{permission && (
				<>
					<Script
						id="gtag-consent-mode-config"
						strategy="lazyOnload"
						type="text/plain"
						data-cookieconsent="ignore"
						dangerouslySetInnerHTML={{
							__html: `window.dataLayer = window.dataLayer || [];
							function gtag() { dataLayer.push(arguments)}
								gtag("consent", "default", {
        							ad_storage: "denied",
        							analytics_storage: "denied",
        							functionality_storage: "denied",
        							personalization_storage: "denied",
        							security_storage: "granted",
        							wait_for_update: 500
     							});
      						gtag("set", "ads_data_redaction", true);`,
						}}
					/>
					<Script
						id="google-tag-manager"
						strategy="afterInteractive"
						data-cookieconsent="ignore"
						dangerouslySetInnerHTML={{
							__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','GTM-N94CQV');`,
						}}
					/>
					<Script
						src="https://www.googletagmanager.com/gtag/js?id=AW-601638299"
						strategy="afterInteractive"
					/>
					{renderCookieConsent && (
						<Script
							id="Cookiebot"
							src="https://consent.cookiebot.com/uc.js"
							data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_CLIENT_ID}
							type="text/javascript"
							data-culture={locale}
							data-blockingmode="ignore"
						/>
					)}
				</>
			)}
		</>
	);
};

export default TrackingScripts;

import { createSlice } from '@reduxjs/toolkit';

import { CustomerInitialState as initialState } from './initial';

const Customer = createSlice({
	initialState,
	name: 'customer',
	reducers: {
		resetCustomerSlice() {
			return {
				...initialState,
			};
		},
		setRequest: (state, action) => action.payload,
	},
});
export const customerStateReducerName = Customer.name;
export const customerStateReducer = Customer.reducer;
export const { resetCustomerSlice, setRequest } = Customer.actions;

export default Customer;

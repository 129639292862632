import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import { MarketplaceMapinitialState as initialState } from '@/redux/StateSlices/MarketplaceMap/initial';

import { IMarketplaceMapStore } from './index.d';

const MarketplaceMap = createSlice({
	initialState,
	name: 'marketplaceMap',
	reducers: {
		resetState() {
			return {
				...initialState,
			};
		},

		setCreditLimit: (state, action: PayloadAction<number>) => {
			state.creditLimit = action.payload;
		},

		setCurrentPage: (state, action: PayloadAction<number, string>) => {
			state.currentPage = action.payload;
		},

		setDealerships: (state, action: PayloadAction<IStoreAndAPIShared.IDealership[], string>) => {
			state.dealerships = action.payload;
		},

		setFullMap: (state, action: PayloadAction<boolean, string>) => {
			state.fullMap = action.payload;
		},

		setHoveredPartner: (
			state,
			action: PayloadAction<IStoreAndAPIShared.IDealership | null, string>
		) => {
			state.hoveredPartner = action.payload;
		},

		setIsMainDealershipActive: (state, action: PayloadAction<boolean>) => {
			state.isMainDealershipActive = action.payload;
		},

		setMapCenterPoint: (state, action: PayloadAction<IMarketplaceMapStore.LatLong, string>) => {
			state.mapCenterPoint = action.payload;
		},

		setScrolledPartner: (state, action: PayloadAction<IStoreAndAPIShared.IDealership, string>) => {
			state.scrolledPartner = action.payload;
		},

		setSelectedPartner: (
			state,
			action: PayloadAction<IStoreAndAPIShared.IDealership | null, string>
		) => {
			state.selectedPartner = action.payload;
		},
		setShowPinNames: (state, action: PayloadAction<boolean, string>) => {
			state.showPinNames = action.payload;
		},
		setShowSearch: (state, action: PayloadAction<boolean, string>) => {
			state.showSearch = action.payload;
		},
		setTokenFlag: (state, action: PayloadAction<boolean>) => {
			state.tokenFlag = action.payload;
		},
		setUserLocation: (state, action: PayloadAction<IMarketplaceMapStore.LatLong, string>) => {
			state.userLocation = action.payload;
		},
		setVehicle: (state, action: PayloadAction<IMarketplaceMapStore.Vehicle, string>) => {
			state.vehicle = action.payload;
		},
		setZoomLevel: (state, action: PayloadAction<number, string>) => {
			state.zoomLevel = action.payload;
		},
	},
});
export const marketplaceMapReducerName = MarketplaceMap.name;
export const marketplaceMapReducer = MarketplaceMap.reducer;
export const {
	resetState,
	setVehicle,
	setMapCenterPoint,
	setShowSearch,
	setUserLocation,
	setDealerships,
	setCurrentPage,
	setScrolledPartner,
	setFullMap,
	setTokenFlag,
	setSelectedPartner,
	setHoveredPartner,
	setZoomLevel,
	setShowPinNames,
	setCreditLimit,
	setIsMainDealershipActive,
} = MarketplaceMap.actions;

export default MarketplaceMap;

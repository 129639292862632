// eslint-disable-next-line check-file/folder-naming-convention
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import Icon from '@/components/Icon';
import DropdownMenu from '@/components/Navbar/DropdownMenu';

import { ILinkItem } from './index.d';

const LinkItem: FC<ILinkItem.IProps> = ({
	href,
	name,
	icon,
	dropdownLinks,
	activeLinks,
	blackList,
	position,
	border,
	user,
}) => {
	const { t } = useTranslation('navbar');
	const router = useRouter();
	const { locale, pathname } = router;
	const isActive = activeLinks?.includes(pathname) || href === pathname;

	// eslint-disable-next-line no-nested-ternary
	const caretIcon = dropdownLinks ? (isActive ? faCaretUp : faCaretDown) : undefined;
	const linkIcon = icon || caretIcon;

	if (locale && blackList?.includes(locale)) {
		return null;
	}

	const getClasses = () => {
		if (position === 'left') {
			return 'relative mr-2 z-10 z-10';
		}
		return 'relative ml-1.5 z-10';
	};

	const onButtonMenuClick = () => {
		if (href === '/dealership/dashboard' && user?.user_rewards) {
			router.push('/dealership/dashboard?newrewards=true');
		} else {
			router.push(href);
		}
	};

	const RenderMenu = ({ hasSubmenu, children }: { hasSubmenu?: boolean; children: ReactNode }) => {
		if (!hasSubmenu) {
			return (
				<Link href={href}>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a className={getClasses()}>{children}</a>
				</Link>
			);
		}

		return (
			<button className={getClasses()} type="button" onClick={onButtonMenuClick}>
				{children}
			</button>
		);
	};

	return (
		<RenderMenu hasSubmenu={!!dropdownLinks?.length}>
			<span className="py-1 flex flex-col group cursor-pointer">
				<span
					className={`${
						border ? 'border rounded-3xl py-0.25' : ''
					} inline-flex px-0.5 items-center space-x-0.5 whitespace-nowrap`}
				>
					<span
						className={`text-n0 ${border ? 'group-hover:text-nav-contrast text-nav-content' : ''}`}
					>
						{t(name)}
					</span>
					{linkIcon ? <Icon iconName={linkIcon} /> : null}
				</span>
				{!border ? (
					<span
						className={`absolute ${
							isActive ? 'bg-nav-content' : 'bg-transparent group-hover:bg-nav-contrast'
						} bottom-0 h-1 w-full rounded-t-2xl`}
					/>
				) : null}
			</span>
			{isActive && dropdownLinks && dropdownLinks.length > 0 ? (
				<DropdownMenu isMobile={false} dropdownLinks={dropdownLinks} user={user} />
			) : null}
		</RenderMenu>
	);
};

export default LinkItem;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line check-file/folder-naming-convention
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { IDropdownMenu } from '@/components/Navbar/DropdownMenu/index.d';
import newRewardsCheck from '@/services/Utils/Url/newRewardsCheck';

const DropdownMenu: FC<IDropdownMenu.IProps> = ({ dropdownLinks, isMobile, user }) => {
	const { t } = useTranslation('navbar');
	const router = useRouter();
	const { locale } = router;

	return (
		<div
			className={`bg-tertiary-content flex px-1 ${
				isMobile
					? 'flex-col py-0.75 rounded-3xl relative mt-1'
					: 'absolute left-0 top-14 flex-row space-x-1 rounded-b-2xl'
			}`}
		>
			{dropdownLinks.map((dropdownLinkItem, dropdownIndex) => {
				const dropdownLink = newRewardsCheck(dropdownLinkItem, !!user?.user_rewards);
				if (dropdownLink.href === '/dealership/rewards' && !user?.user_rewards) {
					return null;
				}
				if (locale && dropdownLinkItem?.blackList?.includes(locale)) {
					return null;
				}
				const isActive = router.pathname === dropdownLink.href.split('?')[0];
				return (
					<>
						{dropdownLink?.visible ? (
							<Link href={dropdownLink.href} key={`${String(dropdownIndex)}-${dropdownLink.name}`}>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<a
									className={`p-1 text-tertiary ${isMobile ? '' : 'text-center'} relative group`}
									onClick={(e: any) => e.stopPropagation()}
								>
									<span className="whitespace-nowrap">{t(dropdownLink.name)}</span>

									{isMobile ? (
										<span
											className={`${
												isActive ? 'bg-primary' : 'bg-transparent group-hover:bg-nav-contrast'
											} absolute -left-4 bottom-0 h-full w-2 rounded-r-2xl`}
										/>
									) : (
										<span
											className={`absolute left-0 ${
												isActive ? 'bg-reverse' : 'bg-transparent group-hover:bg-primary'
											} bottom-0 h-1 w-full rounded-t-2xl`}
										/>
									)}
								</a>
							</Link>
						) : null}
					</>
				);
			})}
		</div>
	);
};

export default DropdownMenu;

const newRewardsCheck = (dropdownLinkItem: any, userRewards: boolean) => {
	let dropdownLink = dropdownLinkItem;
	if (dropdownLink.href === '/dealership/rewards' && userRewards) {
		dropdownLink = { ...dropdownLink, href: '/dealership/rewards?newrewards=true' };
	}
	if (dropdownLink.href === '/dealership/dashboard' && userRewards) {
		dropdownLink = { ...dropdownLink, href: '/dealership/dashboard?newrewards=true' };
	}
	return dropdownLink;
};

export default newRewardsCheck;

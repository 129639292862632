import { ComponentType, ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from '@/redux/store';

type IntrinsicAttributes = {
	[key: string]: any;
};

function withReduxProvider<T extends IntrinsicAttributes>(WrappedComponent: ComponentType<T>) {
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	type Props = IntrinsicAttributes & T & { children?: ReactNode };

	const Component = (props: Props) => (
		<Provider store={store}>
			<WrappedComponent {...(props as T)} />
		</Provider>
	);

	Component.displayName = `withProvider(${displayName})`;

	return Component;
}

export default withReduxProvider;

import { selectTheme } from '@/redux/StateSlices/Theme';
import { useTypedSelector } from '@/redux/store';
import { FALLBACK_THEME, themeConfigurations } from '@/services/Providers/Theme';

const useBrandTheme = () => {
	const theme = useTypedSelector(selectTheme);
	return themeConfigurations[theme] || themeConfigurations[FALLBACK_THEME];
};

export default useBrandTheme;

import Navbar from '@/components/Navbar';
import Ribbon from '@/components/Ribbon';
import TrustpilotScript from '@/components/Widgets/Trustpilot/Script';
import { IStaticLayout } from '@/layouts/StaticLayout/index.d';
import Footer from '@/modules/Footer';
import useBrowserMounted from '@/services/Hooks/useBrowserMounted';

export default function StaticLayout({
	children,
	hideNavbar,
	isDefaultTheme,
	hideMenus,
	hideAuthLinks,
	hideFooter,
}: IStaticLayout.Props) {
	const isBrowserMounted = useBrowserMounted();

	const shouldRenderNavbar = !hideNavbar && isBrowserMounted;
	const shouldRenderFooter = !hideFooter && isBrowserMounted;

	return (
		<>
			<div className="font-text">
				<TrustpilotScript />
				{shouldRenderNavbar ? (
					<Navbar
						isDefaultTheme={isDefaultTheme}
						hideMenus={hideMenus}
						hideAuthLinks={hideAuthLinks}
						disableCleanLogoRedirect={hideMenus}
					/>
				) : null}
				<main>{children}</main>
				{shouldRenderFooter ? <Footer /> : null}
			</div>
			<Ribbon />
		</>
	);
}

import { faCircleX } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import Icon from '@/components/Icon';
import Select from '@/components/Select';
import { COUNTRY_OPTION_LIST } from '@/constants/locales';
import IFooterTypes from '@/modules/Footer/index.d';
import { setBannerClosed, setShowBanner } from '@/redux/StateSlices/Country';
import { useAppDispatch } from '@/redux/store';

const ChangeCountryBanner = () => {
	const router = useRouter();
	const { t } = useTranslation('footer');
	const dispatch = useAppDispatch();
	const [selectedCountry, setSelectedCountry] = useState<any>();

	const initialCountryOptions: IFooterTypes.ICountryOptionsType[] = COUNTRY_OPTION_LIST.map(
		(co: IFooterTypes.ICountryOptionsType) => ({
			...co,
			name: t(`countryNames.${co.countryCode}`),
		})
	);
	const [countryOptions, setCountryOptions] =
		useState<IFooterTypes.ICountryOptionsType[]>(initialCountryOptions);

	const changeLocale = (e: any) => {
		router.push('', '', {
			locale: e?.countryCode,
		});
	};

	const handleCloseBanner = () => {
		dispatch(setShowBanner(false));
		dispatch(setBannerClosed(true));
	};

	// This useEffect hook is used to select and prioritize a country based on the current router locale.
	// It searches the countryOptions array for a matching country, sets it as the selected country, and places it at the top of the list.
	useEffect(() => {
		const opts = [...countryOptions];
		const foundCountry = opts.find((i) => i.countryCode === router?.locale);
		if (foundCountry) {
			const filterRestOfCountries = opts.filter((o) => o.countryCode !== foundCountry.countryCode);
			setSelectedCountry(foundCountry);
			setCountryOptions([foundCountry, ...filterRestOfCountries]);
		}
	}, [router]);

	return (
		<div
			className="h-40 md:h-24 w-full flex bg-secondary-contrast items-center justify-center px-2 bg-europe-map lg:px-6 relative py-1 bg-right bg-no-repeat"
			style={{ backgroundSize: '750px' }}
		>
			<div className="relative flex flex-col md:flex-row items-center justify-between z-50">
				<div className="flex flex-col md:flex-row">
					<div className="lg:w-2/5 text-reverse text-sm mr-4 md:mr-2 pb-1 md:pb-0">
						{t('navbar:changeCountryBanner', { countryName: t('common:countryName') })}
					</div>
					<div className="flex items-center">
						<div className="w-full sm:w-72 hover:cursor-pointer">
							<Select
								options={countryOptions}
								onChange={(e: any) => {
									changeLocale(e);
								}}
								isSearchable={false}
								value={selectedCountry}
								onBlur={() => {}}
								variant="primary"
								controlClassName="bg-white flex items-center"
							/>
						</div>
					</div>
				</div>
				<div className="absolute right-8 top-5 md:top-0 md:flex md:relative">
					<button
						className="ml-4 bg-transparent border-none text-reverse cursor-pointer"
						onClick={handleCloseBanner}
						type="button"
					>
						<Icon iconName={faCircleX} className="text-3xl text-reverse" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ChangeCountryBanner;

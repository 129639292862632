import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/styles/globals.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import { compose } from '@reduxjs/toolkit';
import type { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation as withTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider as NextThemeWrapper } from 'next-themes';
import NextNprogress from 'nextjs-progressbar';
import { ReactNode, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import SEO_CONFIG from '@/../next-seo.config';
import TrackingScripts from '@/components/TrackingScripts';
import LiveChat from '@/components/Widgets/LiveChat';
import { DYNAMIC_LAYOUT, STATIC_LAYOUT } from '@/constants/layouts';
import { UNITED_KINGDOM } from '@/constants/locales';
import DynamicLayout from '@/layouts/DynamicLayout';
import StaticLayout from '@/layouts/StaticLayout';
import withReduxProvider from '@/redux/withReduxProvider';
import useBrandTheme from '@/services/Hooks/useBrandTheme';
import StatsigProvider from '@/services/Providers/StatsigProvider';
import { FALLBACK_THEME, getFavIconProperties } from '@/services/Providers/Theme';
import ThemeProvider from '@/services/Providers/Theme/ThemeProvider';
import withAuthEventProvider from '@/services/Providers/withAuthEventProvider';
import withMarketingLayerProvider from '@/services/Providers/withMarketingLayerProvider';
import { StatsigParameterContext } from '@/services/Utils/Statsig/statsigManager/StatsigParameterContext';

config.autoAddCss = false;
declare global {
	interface Window {
		Cookiebot: any;
		dataLayer: any;
		isOneOfConsent: boolean;
		isCookieSettingsSelected: boolean;
		eventBusRan: boolean;
		isIcPatronChatInit: boolean;
		icPatronChat: any;
		Trustpilot: any;
	}
}

const BumperApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
	Component,
	pageProps,
}: AppLayoutProps) => {
	const { favicon } = useBrandTheme();
	const { locale = UNITED_KINGDOM } = useRouter();

	const isDefaultTheme = pageProps.isdefaultTheme || false;
	const hideTrackingScripts = pageProps.hideTrackingScripts || false;
	const hideCookieConsent = pageProps.hideCookieConsent || false;
	const isSeoAvailable = process.env.NEXT_PUBLIC_ENV === 'production';
	const shouldHideChat = Boolean(pageProps.hideChat);

	const layoutType: typeof STATIC_LAYOUT | typeof DYNAMIC_LAYOUT =
		Component.layoutType || DYNAMIC_LAYOUT;

	const getLayout =
		Component.getLayout ||
		((page: ReactNode) => {
			if (layoutType === STATIC_LAYOUT) {
				return (
					<StaticLayout
						hideNavbar={pageProps.hideNavbar}
						isDefaultTheme={isDefaultTheme}
						hideMenus={pageProps.hideMenus}
						hideAuthLinks={pageProps.hideAuthLinks}
						hideFooter={pageProps.hideFooter}
					>
						{page}
					</StaticLayout>
				);
			}
			return (
				<DynamicLayout
					hideNavbar={pageProps.hideNavbar}
					hideNavbarOnWhiteLabel={pageProps.hideNavbarOnWhiteLabel}
					isDefaultTheme={isDefaultTheme}
					hideMenus={pageProps.hideMenus}
					hideAuthLinks={pageProps.hideAuthLinks}
					hideFooter={pageProps.hideFooter}
				>
					{page}
				</DynamicLayout>
			);
		});

	// Do not delete this, otherwise safari v<13 crashes
	if (typeof window !== 'undefined') {
		window.ResizeObserver = window.ResizeObserver || ResizeObserver;
	}

	useEffect(() => {
		if (
			locale &&
			typeof window !== 'undefined' &&
			typeof window?.localStorage !== 'undefined' &&
			window.isSecureContext
		) {
			localStorage.setItem('locale', locale);
		}
	}, [locale]);

	return getLayout(
		<>
			{hideTrackingScripts ? null : <TrackingScripts renderCookieConsent={!hideCookieConsent} />}
			<LiveChat hideChat={shouldHideChat} />
			<DefaultSeo
				{...SEO_CONFIG}
				{...(isDefaultTheme
					? {
							title: undefined,
							titleTemplate: '%s | Bumper',
					  }
					: {})}
				additionalLinkTags={favicon && !isDefaultTheme ? favicon : getFavIconProperties()}
				dangerouslySetAllPagesToNoIndex={!isSeoAvailable}
				dangerouslySetAllPagesToNoFollow={!isSeoAvailable}
			/>
			<NextNprogress color="#FF733C" options={{ showSpinner: false }} />
			<StatsigProvider>
				<StatsigParameterContext.Provider value={pageProps?.statsigParameters}>
					<NextThemeWrapper forcedTheme={isDefaultTheme ? FALLBACK_THEME : undefined}>
						<ThemeProvider>
							<Component {...pageProps} />
						</ThemeProvider>
					</NextThemeWrapper>
				</StatsigParameterContext.Provider>
			</StatsigProvider>
		</>
	);
};

const enhancers = compose(
	withTranslation,
	withReduxProvider,
	withAuthEventProvider,
	withMarketingLayerProvider
);

export default enhancers(BumperApp);

import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ILinkItem } from '@/components/Navbar/LinkItem/index.d';

export const AuthenticatedLinks: ILinkItem.IProps[] = [
	{
		activeLinks: ['/dealership/application'],
		href: '/dealership/application',
		icon: faPlus,
		name: 'newPayment',
	},
	{
		activeLinks: ['/dealership/applications', '/dealership/payments'],
		dropdownLinks: [
			{
				href: '/dealership/applications',
				name: 'PayLater',
				visible: true,
			},
			{
				href: '/dealership/payments',
				name: 'PayNow',
				visible: true,
			},
		],
		href: '/dealership/applications',
		name: 'myPayments',
	},
	{
		activeLinks: [
			'/dealership/dashboard',
			'/dealership/trustpilot/reviews',
			'/dealership/rewards',
			'/dealership/profile',
			'/dealership/knowledge-base',
		],
		dropdownLinks: [
			{
				href: '/dealership/dashboard',
				name: 'dashboard',
				visible: true,
			},
			{
				blackList: ['de', 'es', 'pt', 'nl'],
				href: '/dealership/trustpilot/reviews',
				name: 'reviews',
				visible: true,
			},
			{
				href: '/dealership/rewards',
				name: 'rewards',
				visible: true,
			},
			{
				href: '/dealership/profile',
				name: 'profile',
				visible: true,
			},
			{
				blackList: ['es'],
				href: '/dealership/knowledge-base',
				name: 'knowledgeBase',
				visible: true,
			},
		],
		href: '/dealership/dashboard',
		name: 'account',
	},
];
export const UnauthenticatedLinks: ILinkItem.IProps[] = [
	{
		blackList: ['de', 'es', 'pt', 'nl'],
		href: '/locations',
		name: 'findAPartner',
	},
	{
		href: '/howitworks',
		name: 'howItWorks',
	},
	{
		href: '/about',
		name: 'aboutUs',
	},
	{
		href: '/blog',
		name: 'blog',
	},
];

import { useRouter } from 'next/router';
import type { FC } from 'react';

import { Caption } from '@/components/Text';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import useBrandTheme from '@/services/Hooks/useBrandTheme';
import useDefaultTheme from '@/services/Hooks/useDefaultTheme';
import useThemeAwareTranslation from '@/services/Hooks/useThemeAwareTranslation';
import { FALLBACK_THEME } from '@/services/Providers/Theme';

const Copyright: FC = () => {
	const { t } = useThemeAwareTranslation('footer');
	const { readableName, name } = useBrandTheme();
	const defaultTheme = useDefaultTheme();
	const router = useRouter();

	if (router.locale === UNITED_KINGDOM || router.locale === IRELAND) {
		return (
			<div className="mt-4 p-1 border border-primary-contrast rounded-1">
				<Caption className="text-left">
					{name === FALLBACK_THEME
						? t('descriptionWithoutSupplier', { supplier: defaultTheme?.readableName || 'Bumper' })
						: t('description', {
								supplier: readableName || 'Bumper',
						  })}
				</Caption>
			</div>
		);
	}
	return null;
};
export default Copyright;

// eslint-disable-next-line check-file/folder-naming-convention
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import Image from '@/components/Image';
import { IAuthLink, IDesktopNavbar } from '@/components/Navbar/DesktopNavbar/index.d';
import LinkItem from '@/components/Navbar/LinkItem';
import NotificationButton from '@/components/Navbar/NotificationButton';
import UserButton from '@/components/Navbar/UserButton';
import { Body } from '@/components/Text/index';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import { UnauthenticatedLinks } from '@/constants/navbar';
import { useMarketingType } from '@/services/Hooks/useMarketingType';

const AuthenticatedAuthLinks: FC<IAuthLink.IProps> = () => (
	<>
		<UserButton isMobile={false} />
		<NotificationButton />
	</>
);

const UnAuthenticatedAuthLinks: FC<IAuthLink.IProps> = ({ hide, homepageType }) => {
	const { t } = useTranslation('navbar');
	const { locale = UNITED_KINGDOM } = useRouter();
	if (hide) return null;
	const isDriverPage = homepageType === 'for-drivers';

	if (locale === IRELAND) return null;
	return (
		<>
			<Button
				onClick={() =>
					router.push(
						homepageType === 'for-drivers' ? '/enter-your-reg' : '/registration/dealership'
					)
				}
				label={isDriverPage ? t('apply') : t('signUp')}
				structure="small"
				textClassName="text-sm font-sans"
				size="medium"
				type="button"
			/>
		</>
	);
};

const DesktopNavbar: FC<IDesktopNavbar.IProps> = ({
	authenticated,
	logo,
	hideAuthLinks,
	user,
	VisibleAuthenticatedLinks,
	homepageType,
}) => {
	const { locale = UNITED_KINGDOM } = useRouter();
	const shouldShowBusinessAndDriversTabs =
		[UNITED_KINGDOM, IRELAND].includes(locale) && homepageType !== 'international';
	const marketingType = useMarketingType();

	const visibleLinkItems = (() => {
		if (authenticated) {
			return VisibleAuthenticatedLinks;
		}
		if (marketingType === 'for-business') {
			return null;
		}
		return UnauthenticatedLinks;
	})();

	return (
		<>
			<div className="flex flex-row px-1 md:px-2 lg:px-6 relative">
				<div className="w-full flex items-center">
					<Link href={authenticated ? '/dealership/application' : '/'}>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a className="cursor-pointer flex items-center gap-0.5">
							<Image client src={logo.src} height={logo.height || 0} width={logo.width || 0} />
							{shouldShowBusinessAndDriversTabs ? (
								<Body className="font-sans" weight="bold">
									{homepageType.replace(/-/g, ' ')}
								</Body>
							) : (
								''
							)}
						</a>
					</Link>
				</div>
				{visibleLinkItems ? (
					visibleLinkItems.map((link, index) => (
						<LinkItem
							blackList={link.blackList}
							icon={link.icon}
							dropdownLinks={link.dropdownLinks}
							key={`${String(index)}-${link.name}`}
							href={link.href}
							activeLinks={link.activeLinks}
							name={link.name}
							user={user}
						/>
					))
				) : (
					<span className="py-1">&nbsp;</span>
				)}
			</div>
			<div className="flex flex-row items-center justify-end right-20 absolute pr-1">
				{authenticated ? (
					<AuthenticatedAuthLinks homepageType={homepageType} />
				) : (
					<UnAuthenticatedAuthLinks hide={hideAuthLinks} homepageType={homepageType} />
				)}
			</div>
		</>
	);
};
export default DesktopNavbar;

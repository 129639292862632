import { useRouter } from 'next/router';

import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import { useUser } from '@/services/Hooks/useUser';
import type { MarketingDecision } from '@/services/Utils/Marketing/getHomePageMarketingType';

const businessPages = ['/for-business', '/registration/dealership', '/partner', '/login'];

const useMarketingType = (): MarketingDecision => {
	const { locale = UNITED_KINGDOM, pathname } = useRouter();
	const { isStaff } = useUser();
	const isUrlBusinessPage = Boolean(businessPages.filter((item) => pathname.includes(item))[0]);

	if (![UNITED_KINGDOM, IRELAND].includes(locale)) {
		return 'international';
	}

	if (isStaff || isUrlBusinessPage) {
		return 'for-business';
	}

	return 'for-drivers';
};

export { useMarketingType };

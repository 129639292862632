/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import { FC } from 'react';

import Image from '@/components/Image';
import DesktopNavbar from '@/components/Navbar/DesktopNavbar';
import { INavbar } from '@/components/Navbar/index.d';
import MobileNavbar from '@/components/Navbar/MobileNavbar';
import NavWrapper from '@/components/Navbar/NavWrapper';
import { AuthenticatedLinks } from '@/constants/navbar';
import { activeThemeSelector } from '@/redux/StateSlices/Theme';
import { useTypedSelector } from '@/redux/store';
import { useMarketingType } from '@/services/Hooks/useMarketingType';
import { useUser } from '@/services/Hooks/useUser';
import { FALLBACK_THEME, themeConfigurations } from '@/services/Providers/Theme';

const Navbar: FC<INavbar.IProps> = ({
	hideMenus,
	hideAuthLinks = false,
	isDefaultTheme,
	disableCleanLogoRedirect,
}) => {
	const storeTheme = useTypedSelector(activeThemeSelector);
	const { userDetails } = useUser();
	const defaultTheme = themeConfigurations[FALLBACK_THEME];
	const brandLogo = !isDefaultTheme && storeTheme.logo ? storeTheme.logo : defaultTheme.logo;
	const { isVisitor, isSessionExist } = useUser();
	const homepageType = useMarketingType();

	const VisibleAuthenticatedLinks = AuthenticatedLinks?.map((data) => {
		const dropdownData = data?.dropdownLinks?.map((item: any) => {
			if (item?.name === 'reviews') {
				// it will be visible: user?.show_trustpilot when we go to live
				return { ...item, visible: userDetails?.show_trustpilot };
			}
			if (item?.name === 'rewards') {
				return { ...item, visible: !!userDetails?.user_rewards };
			}
			return item;
		});
		return { ...data, dropdownLinks: dropdownData };
	});

	const logoUI = disableCleanLogoRedirect ? (
		<div className="pt-0.5">
			<Image
				client
				src={brandLogo.src}
				height={brandLogo.height || 0}
				width={brandLogo.width || 0}
			/>
		</div>
	) : (
		<Link href="/">
			<a className="pt-0.5 cursor-pointer">
				<Image
					client
					src={brandLogo.src}
					height={brandLogo.height || 0}
					width={brandLogo.width || 0}
				/>
			</a>
		</Link>
	);

	if (hideMenus) {
		return (
			<NavWrapper hideMenus={hideMenus}>
				<div className="flex flex-row items-center relative px-1">
					<div className="w-full left-0 z-10 flex flex-row items-center justify-center py-0.5">
						{logoUI}
					</div>
				</div>
			</NavWrapper>
		);
	}
	return (
		<NavWrapper>
			<div className="hidden w-full lg:flex flex-row items-center relative">
				<DesktopNavbar
					logo={brandLogo}
					authenticated={isSessionExist && !isVisitor}
					hideAuthLinks={hideAuthLinks}
					user={userDetails || undefined}
					VisibleAuthenticatedLinks={VisibleAuthenticatedLinks}
					homepageType={homepageType}
				/>
			</div>
			<div className="flex lg:hidden">
				<MobileNavbar
					hideAuthLinks={hideAuthLinks}
					authenticated={isSessionExist && !isVisitor}
					logo={brandLogo}
					user={userDetails || undefined}
					homepageType={homepageType}
					VisibleAuthenticatedLinks={VisibleAuthenticatedLinks}
				/>
			</div>
		</NavWrapper>
	);
};

export default Navbar;

import Link from 'next/link';
import { FC } from 'react';

import Icon from '@/components/Icon/index';
import { INotificationItem } from '@/components/Navbar/NotificationItem/index.d';
import { Caption } from '@/components/Text/index';

const NotificationItem: FC<INotificationItem.IProps> = ({ text, icon, href, variant }) => (
	<Link href={href}>
		{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
		<a>
			<Caption className="flex flex-row space-x-1 items-center">
				<Icon iconName={icon} className="text-n1" variant={variant} />
				<span>{text}</span>
			</Caption>
		</a>
	</Link>
);

export default NotificationItem;

// eslint-disable-next-line check-file/folder-naming-convention
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import { useMarketingType } from '@/services/Hooks/useMarketingType';
import { useUser } from '@/services/Hooks/useUser';

import { INavbarTabs } from './index.d';

const NavbarTabs: FC<INavbarTabs.IProps> = () => {
	const { t } = useTranslation('navbar');
	const { locale = UNITED_KINGDOM, push, pathname } = useRouter();
	const { isStaff } = useUser();
	const homepageType = useMarketingType();
	const isLocaleUKOrIreland = [UNITED_KINGDOM, IRELAND].includes(locale);
	const shouldShowBusinessAndDriversTabs = isLocaleUKOrIreland && homepageType !== 'international';

	const hideBusinessAndDriversContainer =
		(!isStaff && [IRELAND].includes(locale)) || pathname.includes('/customer/retention');

	return (
		<div className="bg-primary w-full">
			{!hideBusinessAndDriversContainer ? (
				<div
					className={`bg-primary-content w-full text-reverse pr-1 h-12 font-sans rounded-b-0 flex items-center md:px-1 lg:px-4 lg:rounded-b-1 ${
						shouldShowBusinessAndDriversTabs ? 'justify-between' : 'justify-end'
					}`}
				>
					{shouldShowBusinessAndDriversTabs ? (
						<div className="flex justify-end lg:justify-start items-center h-full text-xs">
							<div className="pt-0.5 px-0 lg:px-1 flex flex-col justify-between items-center h-full border-tone-contrast-30 border-r">
								<Button
									onClick={() => push('/for-business')}
									variant="none"
									size="small"
									structure="small"
									textClassName="text-reverse text-xs"
									label="For Business"
								/>
								{homepageType === 'for-business' ? (
									<hr className="border-reverse border-b-3 w-2/3" />
								) : null}
							</div>
							<div className="pt-0.5 px-0 lg:px-1 flex flex-col items-center justify-between h-full">
								<Button
									onClick={() => push('/for-drivers')}
									variant="none"
									textClassName="text-reverse text-xs"
									label="For Drivers"
								/>
								{homepageType === 'for-drivers' ? (
									<hr className="border-reverse border-b-3 w-2/3" />
								) : null}
							</div>
						</div>
					) : null}
					{!isStaff ? (
						<>
							<div className="lg:hidden">
								<Button
									onClick={() => push('/login')}
									label="Login"
									size="small"
									type="button"
									structure="small"
									textClassName="text-reverse text-xs"
									variant="reverse-content"
									border
									icon={faRightToBracket}
								/>
							</div>
							<div className="hidden text-reverse justify-end lg:flex lg:mr-2">
								<Button
									onClick={() => push('/login')}
									label={t('login')}
									size="small"
									type="button"
									structure="small"
									textClassName="text-reverse text-xs"
									variant="reverse-content"
									border
									icon={faRightToBracket}
								/>
							</div>
						</>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default NavbarTabs;

import { useLazyLogoutQuery } from '@/redux/APISlices/AuthAPI/index';
import { useLazyGetNotificationsQuery } from '@/redux/APISlices/NotificationAPI';
import { useLazyGetApiLastLoginQuery } from '@/redux/APISlices/UserAPI/index';
import {
	clearUserDetailState,
	setNotifications,
	setUserDetailState,
} from '@/redux/StateSlices/User';
import { IUserStore } from '@/redux/StateSlices/User/index.d';
import { useAppDispatch, useTypedSelector } from '@/redux/store';
import {
	getExistingAccessTokenFromCookies,
	requestNewAccessToken,
	returnDecodedAccessTokenValues,
	setAccessTokenToCookie,
} from '@/services/Auth/AccessToken';
import { captureExceptionAndLogToSentry } from '@/services/Utils/Sentry';

const useUser = () => {
	const dispatch = useAppDispatch();
	const { userDetails, user, notifications } = useTypedSelector<IUserStore.IState>(
		(state: any) => state.userState
	);
	const isAfterSalesStaff = userDetails?.user_type_name === 'After Sales Staff';
	const isSalesStaff = userDetails?.user_type_name === 'Sales Staff';
	// TODO: Technical Debt: user_type_name && real_user_type should be merged in the future with agreement on BE
	const isContactCentreStaff = userDetails?.real_user_type === 'Contact Center Staff';
	const accessToken = getExistingAccessTokenFromCookies();
	const isSessionExist = Boolean(accessToken);
	const { userGroupOfUserIsKnown, userHasNoUserGroup, isStaff, username, isVisitor } =
		returnDecodedAccessTokenValues(accessToken as string);

	const [lastLoginTrigger] = useLazyGetApiLastLoginQuery();
	const [logoutTrigger] = useLazyLogoutQuery();
	const [notificationTrigger] = useLazyGetNotificationsQuery();

	const clearUserDetailApiLastLogin = () => {
		dispatch(clearUserDetailState());
	};

	const getUserDetails = async (): Promise<boolean> => {
		const { data } = await lastLoginTrigger();
		if (data) {
			dispatch(setUserDetailState(data));
			return true;
		}
		return false;
	};

	const serviceAdvisorLogin = async (
		enteredUsername: string,
		enteredPassword: string
	): Promise<boolean> => {
		const token = await requestNewAccessToken({
			password: enteredPassword,
			username: enteredUsername,
		});

		if (token) {
			return getUserDetails();
		}

		return false;
	};

	const visitorLogin = async (): Promise<boolean> => {
		const token = await requestNewAccessToken({
			password: '',
			username: '',
		});

		clearUserDetailApiLastLogin();

		if (token) {
			// dispatch(setAccessToken(token));
			return true;
		}

		captureExceptionAndLogToSentry("Can't get token on visitor login", '', '', {}, 'auth', 'error');

		return false;
	};

	const logout = async (): Promise<boolean> => {
		const { data } = await logoutTrigger();

		if (data?.jwt_token) {
			clearUserDetailApiLastLogin();
			setAccessTokenToCookie(data?.jwt_token);
			return true;
		}

		return visitorLogin();
	};

	const getNotifications = async () => {
		const { data } = await notificationTrigger();

		if (data) {
			dispatch(setNotifications(data));
		}
	};

	return {
		accessToken,
		getNotifications,
		getUserDetails,
		isAfterSalesStaff,
		isContactCentreStaff,
		isSalesStaff,
		isSessionExist,
		isStaff,
		isVisitor,
		logout,
		notifications,
		serviceAdvisorLogin,
		userDetails: userDetails || user,
		userGroupOfUserIsKnown,
		userHasNoUserGroup,
		username,
		visitorLogin,
	};
};

export { useUser };

import { BumperApiReducer, BumperApiReducerName } from '@/redux/APIs/BumperApi';
import { GenericFetchApiReducer, GenericFetchApiReducerName } from '@/redux/APIs/GenericFetchApi';
import { analyticsReducer, analyticsReducerName } from '@/redux/StateSlices/Analytics';
import { applicationReducer, applicationReducerName } from '@/redux/StateSlices/Application';
import { applicationApplyReducer, applicationApplyReducerName } from '@/redux/StateSlices/Apply';
import { bookingReducer, bookingReducerName } from '@/redux/StateSlices/Booking';
import { countryReducer, countryReducerName } from '@/redux/StateSlices/Country';
import { customerStateReducer, customerStateReducerName } from '@/redux/StateSlices/Customer';
import {
	marketplaceApplicationReducer,
	marketplaceApplicationReducerName,
} from '@/redux/StateSlices/MarketplaceApplication';
import {
	marketplaceMapReducer,
	marketplaceMapReducerName,
} from '@/redux/StateSlices/MarketplaceMap';
import { onlineRepayName, onlineRepayReducer } from '@/redux/StateSlices/OnlineRepay';
import { themeReducer, themeReducerName } from '@/redux/StateSlices/Theme';
import { userReducer, userReducerName } from '@/redux/StateSlices/User';

const rootReducer = {
	[BumperApiReducerName]: BumperApiReducer,
	[GenericFetchApiReducerName]: GenericFetchApiReducer,
	[analyticsReducerName]: analyticsReducer,
	[applicationApplyReducerName]: applicationApplyReducer,
	[applicationReducerName]: applicationReducer,
	[bookingReducerName]: bookingReducer,
	[countryReducerName]: countryReducer,
	[customerStateReducerName]: customerStateReducer,
	[marketplaceApplicationReducerName]: marketplaceApplicationReducer,
	[marketplaceMapReducerName]: marketplaceMapReducer,
	[onlineRepayName]: onlineRepayReducer,
	[themeReducerName]: themeReducer,
	[userReducerName]: userReducer,
};

export default rootReducer;

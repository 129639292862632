import { NextComponentType } from 'next';
import { useRouter } from 'next/router';
import { ComponentType, createContext, useEffect } from 'react';

import { subscribe, unsubscribe } from '@/services/Events';
import { useUser } from '@/services/Hooks/useUser';

export const SessionContext = createContext<boolean>(false);

function withAuthEventProvider<T extends NextComponentType>(WrappedComponent: ComponentType<T>) {
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const Component = (props: any) => {
		// TODO: Write a TSDoc

		const {
			accessToken,
			logout,
			isSessionExist,
			isVisitor,
			getNotifications,
			notifications,
			getUserDetails,
		} = useUser();
		const router = useRouter();

		const triggerNotifications = () => {
			if (isSessionExist && !isVisitor) {
				getNotifications();
			}
		};

		useEffect(() => {
			subscribe('StaffTokenExpire', async () => {
				const result = await logout();

				if (result) {
					router.push('/login');
				}
				// TODO: Router login
			});
			return () => {
				unsubscribe('StaffTokenExpire', () => null);
			};
		}, []);

		useEffect(() => {
			subscribe('PasswordIsExpired', async (data: any) => {
				const result = await logout();

				if (result) {
					router.push({
						pathname: '/reset-password',
						query: {
							type: 'forced',
							username: data?.detail?.username || '',
						},
					});
				}
			});
			return () => {
				unsubscribe('PasswordIsExpired', () => null);
			};
		}, []);

		useEffect(() => {
			router.events.on('routeChangeComplete', triggerNotifications);
			return () => {
				router.events.off('routeChangeComplete', triggerNotifications);
			};
		}, []);

		useEffect(() => {
			triggerNotifications();
		}, [isSessionExist, isVisitor]);

		useEffect(() => {
			if (notifications?.is_api_last_login_changed) {
				getUserDetails();
			}
		}, [notifications]);

		return (
			<SessionContext.Provider value={Boolean(accessToken)}>
				<WrappedComponent {...(props as T)} />
			</SessionContext.Provider>
		);
	};

	Component.displayName = `withVisitorTokenProvider(${displayName})`;

	return Component;
}

export default withAuthEventProvider;

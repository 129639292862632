import { FC } from 'react';

import { ILabel } from '@/components/Label/index.d';
import { Footnote } from '@/components/Text';

const ErrorLabel: FC<ILabel.IErrorLabel> = ({
	error,
	role,
	variant = 'error',
	customClassName,
}) => (
	<Footnote
		role={role}
		variant={variant}
		className={`h-1 ml-1 mt-0.5 inline-block ${customClassName}`}
	>
		{error}
	</Footnote>
);

export default ErrorLabel;

import { createElement, FC } from 'react';

import { IText, Variant, Weight } from '@/components/Text/index.d';
import { filterTextProps } from '@/services/Utils/Events/FilterComponents';

const xlTitle = {
	className: 'text-n8 md:text-n10 whitespace-pre-line',
};

const largerTitle = {
	className: 'text-n7 md:text-n8 lg:text-n10 whitespace-pre-line',
};

const largeTitle = {
	className: 'text-n6 md:text-n8 lg:text-n10 whitespace-pre-line',
};

const title1 = {
	className: 'text-n5 md:text-n7 lg:text-n9 whitespace-pre-line',
};

const title2 = {
	className: 'text-n4 md:text-n6 lg:text-n8 whitespace-pre-line',
};

const title3 = {
	className: 'text-n3 md:text-n4 lg:text-n6 whitespace-pre-line',
};

const title4 = {
	className: 'text-n2 md:text-n3 lg:text-n4 whitespace-pre-line',
};

const largeButton = {
	className: 'text-n2 whitespace-pre-line',
};

const headline = {
	className: 'text-n3 lg:text-n4 whitespace-pre-line',
};

const footnote = {
	className: 'text-n-3 whitespace-pre-line',
};

const largeBody = {
	className: 'text-n1 whitespace-pre-line',
};

const body = {
	className: 'text-n0 whitespace-pre-line',
};

const caption = {
	className: 'text-n-1 whitespace-pre-line',
};

const captionV2 = {
	className: 'text-n-2 md:text-n-1 whitespace-pre-line',
};

const getWeight = (weight: Weight) => {
	switch (weight) {
		case 'bold':
			return 'font-text font-bold leading-content';
		case 'heading':
			return 'font-headline leading-heading';
		default:
			return 'font-text font-content leading-content';
	}
};

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'primary':
			return 'text-primary-content';
		case 'secondary':
			return 'text-secondary-content';
		case 'secondary-contrast':
			return 'text-secondary';
		case 'tertiary':
			return 'text-tertiary-content';
		case 'reverse':
			return 'text-reverse-content';
		case 'reverse-base':
			return 'text-reverse';
		case 'success':
			return 'text-success';
		case 'error':
			return 'text-error';
		case 'warning':
			return 'text-warning';
		case 'accent':
			return 'text-accent';
		case 'accent-contrast':
			return 'text-accent-contrast';
		case 'contrast':
			return 'text-tone-contrast';
		case 'contrast-5':
			return 'text-tone-contrast-5';
		case 'contrast-30':
			return 'text-tone-contrast-30';
		case 'contrast-50':
			return 'text-tone-contrast-50';
		default:
			return 'text-primary-content';
	}
};

export const Text: FC<IText.ITextProps & IText.IBaseTextProps> = (props) => {
	const {
		component,
		className = '',
		weight = 'content',
		variant = 'primary',
		children,
		...componentProps
	} = props;

	const filteredProps = filterTextProps(componentProps);

	const p = {
		...filteredProps,
		className: `${component.className} ${getWeight(weight)} ${getColor(variant)} ${className}`,
	};

	return createElement(component.component, p, children);
};

export const XLTitle: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h1',
	...props
}) => <Text component={{ ...xlTitle, component }} {...props} weight={weight} />;

export const LargeTitle: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h1',
	...props
}) => <Text component={{ ...largeTitle, component }} {...props} weight={weight} />;

export const LargerTitle: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h1',
	...props
}) => <Text component={{ ...largerTitle, component }} {...props} weight={weight} />;

export const Title1: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h1',
	...props
}) => <Text component={{ ...title1, component }} {...props} weight={weight} />;

export const Title2: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h2',
	...props
}) => <Text component={{ ...title2, component }} {...props} weight={weight} />;

export const Title3: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h3',
	...props
}) => <Text component={{ ...title3, component }} {...props} weight={weight} />;

export const Title4: FC<IText.ITextComponentProps> = ({
	weight = 'heading',
	component = 'h4',
	...props
}) => <Text component={{ ...title4, component }} {...props} weight={weight} />;

export const LargeButton: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...largeButton, component }} {...props} weight={weight} />;

export const Headline: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...headline, component }} {...props} weight={weight} />;

export const Footnote: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'span',
	...props
}) => <Text component={{ ...footnote, component }} {...props} weight={weight} />;

export const LargeBody: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...largeBody, component }} {...props} weight={weight} />;

export const Body: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...body, component }} {...props} weight={weight} />;

export const Caption: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...caption, component }} {...props} weight={weight} />;

export const CaptionV2: FC<IText.ITextComponentProps> = ({
	weight = 'content',
	component = 'p',
	...props
}) => <Text component={{ ...captionV2, component }} {...props} weight={weight} />;

export default {
	Body,
	Caption,
	CaptionV2,
	Footnote,
	Headline,
	LargeBody,
	LargeButton,
	LargeTitle,
	LargerTitle,
	Text,
	Title1,
	Title2,
	Title3,
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'reduxjs-toolkit-persist';
// @ts-ignore
import storage from 'reduxjs-toolkit-persist/lib/storage'; // defaults to localStorage for web

import middleware from '@/redux/middleware';

import rootReducer from './rootReducer';

const persistConfig = {
	key: 'root',
	storage,
	version: 1,
	// !! Important: Do no persist API slices as it can lead to phantom subscriptions
	whitelist: [
		'userState',
		'theme',
		'affiliate',
		'country',
		'onlineRepayState',
		'applyRequestState',
		'booking',
		'customer',
		'integration',
		'marketplaceMap',
		'marketplaceApplication',
		'applicationState',
		'analytics',
		'applicationApply',
	],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));

const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(middleware),
	reducer: persistedReducer,
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer as any));
}

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const persistor = persistStore(store);

export { persistor, store };

import {
	faCaretDown,
	faCaretUp,
	faCheck,
	faExclamation,
	faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { CSSProperties, FC, forwardRef, useEffect, useState } from 'react';
import ReactSelect, { components as C } from 'react-select';

import Icon from '@/components/Icon';
import Image from '@/components/Image';
import ErrorLabel from '@/components/Label/ErrorLabel';
import ISelect from '@/components/Select/index.d';
import { Body, Text } from '@/components/Text';

const baseIconStyle = 'w-5 h-5';
const iconStyle = `mr-0.5 ${baseIconStyle}`;

const defaultExtractor = (option: any): ISelect.IExtractorResult => ({
	id: option?.id,
	name: option?.name,
});

const getVariant = (variant: ISelect.Variant) => {
	switch (variant) {
		case 'success':
			return 'border-success';
		case 'error':
			return 'border-error';
		case 'primary':
			return 'border-primary-content';
		default:
			return 'border-tone-contrast-50';
	}
};

const getSelectedBorder = (active: boolean, selected: boolean, disabled: boolean) => {
	if (disabled) {
		return 'border-reverse';
	}

	if (selected) {
		return 'border-success';
	}

	if (active) {
		return 'border-tertiary';
	}

	return 'border-reverse';
};

const colourStyles: any = {
	control: () => null,
	dropdownIndicator: () => null,
	indicatorSeparator: () => null,
	indicatorsContainer: () => null,
	input: (base: CSSProperties) => ({
		...base,
		padding: 0.6,
	}),
	menu: (base: CSSProperties) => ({
		...base,
		borderRadius: '100%',
	}),
	menuPortal: () => null,
	option: () => null,
	placeholder: () => null,
};

const Control: FC<ISelect.IControl> = ({
	variant = 'default',
	error = '',
	testId,
	children,
	customErrorLabelVariant = 'error',
	controlClassName = '',
	...rest
}) => {
	const currentValue: any = rest?.getValue();
	const currentImage = currentValue[0]?.img;
	return (
		<C.Control {...rest}>
			<div
				data-testid={testId}
				data-cy={rest.dataCy}
				className={`border ${getVariant(
					variant
				)} rounded-full p-0.5 relative w-full bg-reverse ${controlClassName}`}
			>
				{currentImage?.src && (
					<div className="ml-0.75 flex items-center border border-primary-content">
						<Image
							src={currentImage?.src}
							width={currentImage?.width || 24}
							height={currentImage?.height || 18}
						/>
					</div>
				)}
				<Text
					component={{
						className: 'flex flex-row items-center p-0 text-n0 relative w-full whitespace-pre-line',
						component: 'span',
					}}
				>
					{children}
				</Text>
			</div>
			{!rest.selectProps.menuIsOpen && error && variant === 'error' && (
				<ErrorLabel error={error} role="alert" variant={customErrorLabelVariant} />
			)}
		</C.Control>
	);
};

const Placeholder: FC<ISelect.IPlaceholder> = ({ children }) => (
	<Body variant="contrast-30" className="absolute top-0 py-0.25 px-0.5">
		{children}
	</Body>
);

const DropdownIndicator: FC<ISelect.IDropdownIndicator> = ({ variant, ...rest }) => {
	const isOpen = rest.selectProps.menuIsOpen;
	const { isLoading } = rest.selectProps;
	const isError = variant === 'error';
	const isSuccess = variant === 'success';
	const caretVariant = variant === 'primary' ? 'primary' : 'tone-contrast-30';

	if (isLoading) {
		return (
			<C.DropdownIndicator {...rest}>
				<div className="flex flex-row items-center px-0.5">
					<Icon iconName={faSpinner} variant="primary" className="animate-spin" />
				</div>
			</C.DropdownIndicator>
		);
	}

	return (
		<C.DropdownIndicator {...rest}>
			<div className="flex flex-row items-center px-0.5">
				{isError && <Icon iconName={faExclamation} variant="error" className={iconStyle} />}
				{isSuccess && <Icon iconName={faCheck} variant="success" className={iconStyle} />}
				<Icon
					variant={caretVariant}
					iconName={isOpen ? faCaretUp : faCaretDown}
					className={baseIconStyle}
				/>
			</div>
		</C.DropdownIndicator>
	);
};

const Menu: FC<ISelect.IMenu> = ({ children, ...rest }) => (
	<C.Menu {...rest}>
		<div className="flex flex-col rounded-3xl mt-0 bg-reverse px-0.5  py-0.25 w-full border overflow-hidden">
			{children}
		</div>
	</C.Menu>
);

const Option: FC<ISelect.IOption> = ({ children, data, ...rest }) => (
	<C.Option data={data} {...rest}>
		<div
			className={`${rest.isFocused ? 'bg-tertiary' : 'bg-reverse'} ${getSelectedBorder(
				rest.isFocused,
				rest.isSelected,
				rest.isDisabled
			)} border rounded-full  my-0.25 cursor-pointer flex`}
			data-cy="select-option"
		>
			{/* @ts-ignore */}
			{data?.img?.src && (
				<div className="ml-0.75 flex items-center">
					{/* @ts-ignore */}
					<Image src={data.img.src} width={data.img.width || 24} height={data.img.width || 18} />
				</div>
			)}
			<Body
				variant="primary"
				className="flex flex-row flex-1 py-0.75 pl-0.75 pr-0.25 justify-between items-center"
			>
				{children}
				{rest.isSelected && !rest.isDisabled && (
					<Icon iconName={faCheck} variant="success" className={iconStyle} />
				)}
			</Body>
		</div>
	</C.Option>
);

const Select = forwardRef<any, ISelect.IProps>((p, ref) => {
	const {
		variant,
		options,
		error,
		onChange,
		onBlur,
		testId,
		extractor = defaultExtractor,
		value,
		placeholder,
		name,
		isLoading,
		customErrorLabelVariant = 'error',
		controlClassName,
		...rest
	} = p;

	const selectOptions = options?.map((option) => ({
		...option,
		label: extractor(option).name,
		value: extractor(option).id,
	}));

	const [defaultValue, setDefaultValue] = useState<any | undefined>(undefined);

	useEffect(() => {
		if (value) {
			setDefaultValue({ ...value, label: extractor(value).name, value: extractor(value).id });
		}
	}, [value]);

	const firstOption = selectOptions ? selectOptions?.[0] : null;

	useEffect(() => {
		if (!placeholder && firstOption && !defaultValue) {
			setDefaultValue(firstOption);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [placeholder, firstOption]);

	const handleChange = (val: any) => {
		if (onChange) onChange(val);
		if (onBlur) onBlur(val);
	};

	return (
		<ReactSelect
			{...rest}
			options={selectOptions}
			ref={ref}
			value={defaultValue}
			placeholder={placeholder}
			id={name}
			name={name}
			isLoading={isLoading}
			data-cy={testId}
			aria-errormessage={error}
			components={{
				Control: ({ children, ...controlProps }) => (
					<Control
						{...controlProps}
						testId={testId}
						dataCy={testId}
						error={error}
						variant={variant}
						customErrorLabelVariant={customErrorLabelVariant}
						controlClassName={controlClassName}
					>
						{children}
					</Control>
				),
				DropdownIndicator: (dropdownProps) => (
					<DropdownIndicator {...dropdownProps} variant={variant} />
				),
				LoadingIndicator: () => null,
				Menu,
				Option,
				Placeholder,
			}}
			onChange={handleChange}
			onBlur={onBlur}
			styles={colourStyles}
		/>
	);
});

export default Select;

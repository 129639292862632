export type EventName =
	| 'StaffTokenExpire'
	| 'PasswordIsExpired'
	| 'NewAccessTokenIsSet'
	| 'TrustpilotReady';
export type Listener = (arg?: any) => void;

const subscribe = (eventName: EventName, listener: Listener) => {
	document.addEventListener(eventName, listener);
};

const unsubscribe = (eventName: EventName, listener: Listener) => {
	document.removeEventListener(eventName, listener);
};

const publish = (eventName: EventName, data: Record<string, any>) => {
	const event = new CustomEvent(eventName, { detail: data });
	document.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };

const SEO_CONFIG = {
	additionalMetaTags: [
		{
			content: 'BUMPER',
			name: 'keywords',
		},
		{
			content: 'Bumper',
			name: 'author',
		},
		{
			content: 'pqOTE6xRiNVM-mkFTF4oirybaF55uG-UiY67WcoFFtc',
			name: 'google-site-verification',
		},
	],
	defaultTitle: 'Bumper',
	description:
		'Split the Cost of Your Repairs into Manageable Interest Free Instalments at Bumper. 0% Interest Free · Online & Paperless · No Fees · No Interest · Fast Application Process',
	openGraph: {
		locale: 'en_GB',
		site_name: 'Bumper',
		type: 'website',
		url: process.env.NEXT_PUBLIC_SITE_URL || 'https://www.bumper.co.uk',
	},
};

export default SEO_CONFIG;

// faq: https://github.com/garmeeh/next-seo#additional-meta-tags

import Image from '@/components/Image';

const Ribbon = () => (
	<>
		{process.env.NEXT_PUBLIC_ENV === 'stg' && (
			<div className="fixed bottom-0 ml-1 mb-1 z-50">
				<Image src="images/Icons/Ribber.svg" width={87} height={87} />
			</div>
		)}
	</>
);

export default Ribbon;

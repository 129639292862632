import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAnalyticsStore } from '@/redux/StateSlices/Analytics/index.d';
import { AnalyticsInitialState as initialState } from '@/redux/StateSlices/Analytics/initial';

const Analytics = createSlice({
	initialState,
	name: 'analytics',
	reducers: {
		setEventStore: (state, action: PayloadAction<IAnalyticsStore.IState['eventStore']>) => {
			state.eventStore = action.payload;
		},
	},
});

export const analyticsReducerName = Analytics.name;
export const analyticsReducer = Analytics.reducer;
export const { setEventStore } = Analytics.actions;

export default Analytics;
